import * as actions from './actionTypes';

export const setIsMute = payload => ({
  type: actions.SET_IS_MUTE,
  payload: payload
});
export const setIsSip = payload => ({
  type: actions.SET_IS_SIP,
  payload: payload
});
export const setIsReg = payload => ({
  type: actions.SET_IS_REG,
  payload: payload
});
export const setIsIncoming = payload => ({
  type: actions.SET_IS_INCOMMING,
  payload: payload
});
export const setIsOutgoing = payload => ({
  type: actions.SET_IS_OUTGOING,
  payload: payload
});
export const setIamincall = payload => ({
  type: actions.SET_IAMINCALL,
  payload: payload
});
export const setTimerincall = payload => ({
  type: actions.SET_TIMERINCALL,
  payload: payload
});
export const setOuttel = payload => ({
  type: actions.SET_OUTTEL,
  payload: payload
});
export const setMtelsip = payload => ({
  type: actions.SET_MTELSIP,
  payload: payload
});
export const setIsPause = payload => ({
  type: actions.SET_IS_PAUSE,
  payload: payload
});
export const setIsHold = payload => ({
  type: actions.SET_IS_HOLD,
  payload: payload
});
export const setIsPauseTimer = payload => ({
  type: actions.SET_IS_PAUSE_TIMER,
  payload: payload
});
export const setIsPausePerm = payload => ({
  type: actions.SET_IS_PAUSE_PERM,
  payload: payload
});
export const setSession = payload => ({
  type: actions.SET_SESSION,
  payload: payload
});
export const setUa = payload => ({
  type: actions.SET_UA,
  payload: payload
});
export const setLocalStream = payload => ({
  type: actions.SET_LOCAL_STREAM,
  payload: payload
});
export const setScheme = payload => ({
  type: actions.SET_SCHEME,
  payload: payload
});
export const setSipLoading = payload => ({
  type: actions.SET_SIP_LOADING,
  payload: payload
});
