
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import TooltipItem from '../../common/TooltipItem'
import { faTimes, faVolumeOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import AppContext from 'context/Context'

const MuteBtn = () => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { session, isMute, iamincall } = useSelector(state => state.sip);


    const toggleMute = () => {
        if (session) {
            if (session.isMuted()['audio']) {
                session.unmute();
            } else {
                session.mute();
            }
        }
    }

    return (

        <TooltipItem
            tooltipText={isMute ? 'Включить звук' : 'Выключить звук'}
            placement={'bottom'}
        >
            <Button
                variant={isMute ? "warning" : "falcon-warning"}
                className={classNames(' px-2 text-nowrap shadow-none', { "bg-200": !isDark && !isMute })}
                size='sm'
                onClick={() => {
                    toggleMute()
                }}
                disabled={!iamincall}
            >
                <FontAwesomeIcon icon={faVolumeOff} size='sm' className='me-1' />
                <FontAwesomeIcon icon={faTimes} size='sm' />
            </Button>
        </TooltipItem>
    )
}

export default MuteBtn