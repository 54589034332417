
import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Accordion, Button, Dropdown, Offcanvas } from 'react-bootstrap'
import InputMask from 'react-input-mask';

import { SipContext } from 'context/Context';

import SoftBadge from 'components/common/SoftBadge';

import 'assets/scss/chunks/sip-mobile.scss'
import CallHistory from './CallHistory';
import RingtonesSelect from './RingtonesSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { FormatPhone, renderTime } from 'helpers/utils';
import AnswerBtn from '../buttons/AnswerBtn';
import HangUpBtn from '../buttons/HangUpBtn';
import MobileMuteBtn from '../buttons/mobile/MobileMuteBtn';
import Keyboard from './Keyboard';
import MobileKeyboardBtn from '../buttons/mobile/MobileKeyboardBtn';
import PauseBtn from '../buttons/PauseBtn';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { phoneInputTypes } from './sipInitialBase';
import { MdOutlinePhonelinkSetup } from 'react-icons/md';
import { BsTelephoneForwardFill } from 'react-icons/bs';
import TransferBlock from './TransferBlock';
import Loader from 'components/common/Loader';
import Timer from './Timer';
import MobileHoldBtn from '../buttons/mobile/MobileHoldBtn';
import PhoneInfoList from './PhoneInfoList';

const SipBoxMobile = ({
    handleToggleRegisterSip,
    handleDisconnectSip,
    handleConnectSip,
    handleCall,
    keyboardArray,
    handleKeyboardClick
}) => {


    const { headerOpen, setHeaderOpen, phoneInputContext, setPhoneInputContext } = useContext(SipContext);

    const { iamincall, isReg, isSip, isIncoming, isOutgoing, timerincall, outtel, mtelsip, isPauseTimer, isPausePerm, sipLoading } = useSelector(state => state.sip);

    const [phoneInput, setPhoneInput] = useState(phoneInputContext);
    const [inputType, setInputType] = useState(phoneInputTypes.phone);


    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const [isOpenTransfer, setIsOpenTransfer] = useState(false);

    useEffect(() => {
        if (!isOutgoing) {
            const type = Object.keys(phoneInputTypes).find(key =>
                phoneInputTypes[key].length === phoneInput.length
            )
            if (type) setInputType(phoneInputTypes[type])
        }
    }, [isOutgoing]);

    useEffect(() => {
        if (phoneInputContext) {
            if(phoneInputContext.length === 6) setInputType(phoneInputTypes.inner)
            if(phoneInputContext.length === 4) setInputType(phoneInputTypes.four)
            setPhoneInput(phoneInputContext)
            setPhoneInputContext('')
        }
    }, [phoneInputContext]);

    return (
        <div>
            <Offcanvas
                placement={'bottom'}
                show={headerOpen}
                onHide={() => setHeaderOpen(false)}
                className='sip-offcanvas'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Flex alignItems={'center'}>
                            <SoftBadge bg={isSip ? 'success' : 'warning'} className='me-2'>
                                {
                                    isSip ? 'Онлайн' : 'Оффлайн'
                                }
                            </SoftBadge>
                            {
                                !isReg &&
                                <SoftBadge bg={'danger'} className='me-2'>
                                    Не зарегестрирован
                                </SoftBadge>
                            }
                            {
                                isPauseTimer &&
                                <SoftBadge bg={'warning'}>
                                    <FontAwesomeIcon icon={faPause} className='me-2' />
                                    Временная пауза
                                </SoftBadge>
                            }
                            {
                                isPausePerm &&
                                <SoftBadge bg={'warning'}>
                                    <FontAwesomeIcon icon={faPause} className='me-2' />
                                    Постоянная пауза
                                </SoftBadge>
                            }
                            {
                                sipLoading && <Loader variant='primary' size='lg' className='d-inline-flex' />
                            }
                        </Flex>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='px-0'>
                    <div className='sip-mobile-container'>
                        {
                            !isSip &&
                            <>
                                <Button variant='success' size='lg' className='w-100' onClick={handleConnectSip}>Включить</Button>
                            </>
                        }
                        {
                            isSip &&
                            <>
                                {
                                    isOutgoing &&
                                    <Flex alignItems={'center'}>
                                        <div className='flex-grow-1'>
                                            {
                                                timerincall &&
                                                <div className='fs--1 mb-1'>
                                                    <Timer />
                                                </div>
                                            }
                                            <h5>
                                                Исходящий звонок
                                            </h5>
                                            {
                                                mtelsip ?
                                                    <h5 className='fs-1 text-500' >
                                                        {mtelsip ? FormatPhone(mtelsip) : 'mtelsip не определен'}
                                                    </h5>
                                                    :
                                                    <h5 className='fs-1 text-500' >
                                                        {'не определен'}
                                                    </h5>
                                            }
                                        </div>

                                        {
                                            iamincall &&
                                            <Button
                                                variant="info"
                                                className={`shadow-none me-2`}
                                                size={'lg'}
                                                onClick={() => setIsOpenTransfer(prev => !prev)}
                                            >
                                                <BsTelephoneForwardFill />
                                            </Button>
                                        }
                                        <HangUpBtn isMobile={true} />
                                    </Flex>
                                }
                                {
                                    isIncoming &&
                                    <Flex alignItems={'center'}>
                                        <div className='flex-grow-1'>
                                            {
                                                timerincall && <div className='fs--1 mb-1'>
                                                    <Timer />
                                                </div>
                                            }
                                            <h5> Входящий звонок </h5>
                                            {
                                                mtelsip &&
                                                <h5 className='fs-1 text-600'>
                                                    {FormatPhone(mtelsip)}
                                                </h5>
                                            }
                                            {
                                                outtel ?
                                                    <h5 className='fs-1 text-500 ' >
                                                        <span className='fs--1 text-dark'>На номер:</span> {FormatPhone(outtel)}
                                                    </h5>
                                                    :
                                                    <h5 className='fs-1 text-600' > {'не определен'} </h5>
                                            }
                                        </div>
                                        <AnswerBtn className='me-1' isMobile={true} />
                                        {
                                            iamincall &&
                                            <Button
                                                variant="info"
                                                className={`shadow-none me-2`}
                                                size={'lg'}
                                                onClick={() => setIsOpenTransfer(prev => !prev)}
                                            >
                                                <BsTelephoneForwardFill />
                                            </Button>
                                        }
                                        <HangUpBtn isMobile={true} />
                                    </Flex>
                                }
                                {
                                    (!isIncoming && !isOutgoing) &&
                                    <Flex alignItems={'center'}>
                                        <Dropdown className='me-2'>
                                            <Dropdown.Toggle
                                                bsPrefix='toggle'
                                                variant='link'
                                                size='sm'
                                                className='px-0 py-0 text-900 unfocus'
                                            >
                                                <MdOutlinePhonelinkSetup size={40} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='py-0'>
                                                {
                                                    Object.keys(phoneInputTypes).map((key, index) => {
                                                        const item = phoneInputTypes[key]
                                                        return (
                                                            <Dropdown.Item
                                                                className='py-3 fs-1'
                                                                key={index + 1}
                                                                disabled={phoneInputTypes[key].value === inputType.value}
                                                                onClick={() => {
                                                                    if (phoneInputTypes[key].value !== inputType.value) {
                                                                        setInputType(phoneInputTypes[key])
                                                                        setPhoneInput('')
                                                                    }
                                                                }}
                                                            >
                                                                {item.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <InputMask
                                            type='tel'
                                            className={`form-control form-control-lg me-2 `}
                                            mask={inputType.mask}
                                            autoComplete="off"
                                            placeholder={inputType.placeholder}
                                            value={phoneInput}
                                            onChange={
                                                (e) => setPhoneInput(e.target.value)
                                            }
                                        />
                                        <Button
                                            size='lg'
                                            variant='success'
                                            disabled={phoneInput.replaceAll(/[^\d]/g, '').length !== 11}
                                            onClick={() => handleCall(phoneInput)}
                                        >
                                            <FontAwesomeIcon icon={'phone'} size='lg' />
                                        </Button>
                                    </Flex>
                                }
                                <Flex alignItems={'center'} className='mt-5 mb-4'>

                                    <MobileKeyboardBtn
                                        isMobile={true}
                                        isOpen={isOpenKeyboard}
                                        handleToggle={() => setIsOpenKeyboard(prev => !prev)}
                                        disabled={!iamincall}
                                    />
                                    <span className='d-block mx-1' />
                                    <MobileMuteBtn />
                                    <span className='d-block mx-1' />
                                    <MobileHoldBtn />
                                </Flex>
                            </>
                        }

                        {
                            isOpenKeyboard &&
                            <Keyboard
                                isMobile={true}
                                keyboardArray={keyboardArray}
                                handleKey={handleKeyboardClick}
                                handleClose={() => setIsOpenKeyboard(prev => !prev)}
                            />
                        }
                        {
                            isOpenTransfer &&
                            <TransferBlock
                                isMobile={true}
                                handleClose={() => setIsOpenTransfer(prev => !prev)}
                            />
                        }
                    </div>
                    {
                        (isIncoming || isOutgoing) &&
                        <PhoneInfoList isMobile={true} />
                    }

                    <Accordion className='my-5'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <span className='fs-2'>История звонков</span>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                <CallHistory isMobile={true} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className='fs-2'>
                                <span className='fs-2'>Выбор рингтона</span>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                <RingtonesSelect isMobile={true} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className='sip-mobile-container'>
                        {
                            isSip &&
                            <>
                                <Button variant='danger' size='lg' className='w-100 mb-3' onClick={handleDisconnectSip}>Выключить</Button>
                                {
                                    isReg ?
                                        <Button
                                            variant='outline-danger'
                                            size='lg'
                                            className='w-100 mb-3'
                                            onClick={handleToggleRegisterSip}
                                        >
                                            Отменить регистрацию
                                        </Button>
                                        :

                                        <Button
                                            variant='success'
                                            size='lg'
                                            className='w-100 mb-3'
                                            onClick={handleToggleRegisterSip}
                                        >
                                            Зарегестрироваться
                                        </Button>
                                }
                                <PauseBtn isMobile={true} />
                            </>
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default SipBoxMobile