import React, { useContext, useEffect, useState } from 'react'
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt, faMusic, faKeyboard, faHistory } from '@fortawesome/free-solid-svg-icons'
import { Card, Button, Dropdown, Form } from 'react-bootstrap'
import InputMask from 'react-input-mask';


import TooltipItem from '../../common/TooltipItem'
import Flex from '../../common/Flex'
import FalconCardHeader from '../../common/FalconCardHeader'
import { FormatPhone } from '../../../helpers/utils'

import '../../../assets/scss/chunks/sip.scss'

import AppContext, { SipContext } from '../../../context/Context';

import { useSelector } from 'react-redux';
import MuteBtn from '../buttons/MuteBtn';
import HoldBtn from '../buttons/HoldBtn';
import ToggleBtn from '../buttons/ToggleBtn';

import RingtonesSelect from './RingtonesSelect';
import AnswerBtn from '../buttons/AnswerBtn';
import HangUpBtn from '../buttons/HangUpBtn';
import { borderColors, phoneInputTypes } from './sipInitialBase';
import PauseBtn from '../buttons/PauseBtn';
import Keyboard from './Keyboard';

import PhoneInfoList from './PhoneInfoList';
import CallHistory from './CallHistory';
import { MdOutlinePhonelinkSetup } from 'react-icons/md';
import TransferBlock from './TransferBlock';
import { BsTelephoneForwardFill } from 'react-icons/bs';
import Loader from 'components/common/Loader';
import Timer from './Timer';

const SipBoxDesktop = ({
    handleToggleRegisterSip,
    handleDisconnectSip,
    handleConnectSip,
    handleCall,
    keyboardArray,
    handleKeyboardClick
}) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { headerOpen, setHeaderOpen, phoneInputContext, setPhoneInputContext } = useContext(SipContext);

    const { iamincall, isReg, isSip, isIncoming, isOutgoing, timerincall, outtel, mtelsip, isPauseTimer, isPausePerm, sipLoading } = useSelector(state => state.sip);

    const toggleHeader = () => setHeaderOpen(prevState => !prevState);


    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const [isOpenRingtone, setIsOpenRingtone] = useState(false);
    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const [isOpenTransfer, setIsOpenTransfer] = useState(false);


    // phoneInput меняет принимает значение при изменении контекста
    const [phoneInput, setPhoneInput] = useState(phoneInputContext);
    const [inputType, setInputType] = useState(phoneInputTypes.phone);


    useEffect(() => {
        if (!isOutgoing) {
            const type = Object.keys(phoneInputTypes).find(key =>
                phoneInputTypes[key].length === phoneInput.length
            )
            if (type) setInputType(phoneInputTypes[type])
        }
    }, [isOutgoing]);

    useEffect(() => {
        if (phoneInputContext) {
            if (phoneInputContext.length === 6) setInputType(phoneInputTypes.inner)
            if (phoneInputContext.length === 4) setInputType(phoneInputTypes.four)
            setPhoneInput(String(phoneInputContext))
            setPhoneInputContext('')
        }
    }, [phoneInputContext]);

    useEffect(() => {
        if (!iamincall) {
            setIsOpenTransfer(false)
        }
    }, [iamincall]);

    return (
        <Draggable
            cancel={'[class*="no-drag"]'}
            bounds="body"
        >
            <div
                className={'jssipModal'}
                style={{
                    visibility: headerOpen ? 'visible' : 'hidden'
                }}
            >
                <div className={`jssipModal-container  ${isPauseTimer ? 'border-warning' : ''}`}
                    style={{
                        borderColor: isDark ? borderColors.dark : borderColors.light,
                    }}
                >
                    <Card.Header
                        className={` py-0 px-3 cursor-pointer ${isPausePerm ? 'bg-warning text-dark' : 'bg-100'}`}
                    >
                        <Flex alignItems={'center'} justifyContent={'between'}>
                            {
                                sipLoading ? <Loader variant='primary' size='sm' /> :
                                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                            }
                            <Flex alignItems={'center'}>
                                <Dropdown
                                    className="no-drag"
                                >
                                    <Dropdown.Toggle bsPrefix="toggle" variant="link" className='text-700' size="sm">
                                        <FontAwesomeIcon icon={'ellipsis-v'} className={isPausePerm ? 'text-dark' : ''} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='pb-0'>
                                        <Dropdown.Item onClick={handleToggleRegisterSip}>
                                            {
                                                isReg ?
                                                    'Отменить регистрацию' :
                                                    'Зарегестрироваться'
                                            }
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => {
                                            isSip ? handleDisconnectSip() : handleConnectSip()
                                        }}>
                                            <Form.Check
                                                type="switch"
                                                id="customSwitchDropdown"
                                                name="customSwitchDropdownName"
                                                className='mt-2'
                                                label={isSip ? 'Выключить' : 'Включить'}

                                                checked={isSip}
                                                onChange={() => {
                                                    isSip ? handleDisconnectSip() : handleConnectSip()
                                                }}
                                            />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className='p-2 cursor-pointer' onClick={toggleHeader}>
                                    <FontAwesomeIcon icon={'times'} />
                                </div>
                            </Flex>
                        </Flex>
                    </Card.Header>
                    <div className='p-0 no-drag'>
                        <Card className=" shadow-none " style={{
                            maxWidth: '33rem',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}>
                            {
                                isSip ?
                                    <>
                                        {
                                            isOutgoing &&
                                            <Card.Body className='px-3 py-2'>
                                                <Flex alignItems={'center'}>
                                                    <div className='flex-grow-1'>
                                                        {
                                                            timerincall &&
                                                            <div className='fs--1 mb-1'>
                                                                <Timer />
                                                            </div>
                                                        }
                                                        <h5>
                                                            Исходящий звонок
                                                        </h5>
                                                        {
                                                            mtelsip ?
                                                                <h5 className='fs-1 text-500' >
                                                                    {mtelsip ? FormatPhone(mtelsip) : 'mtelsip не определен'}
                                                                </h5>
                                                                :
                                                                <h5 className='fs-1 text-500' >
                                                                    {'не определен'}
                                                                </h5>
                                                        }
                                                    </div>
                                                    {
                                                        iamincall &&
                                                        <Button
                                                            variant="info"
                                                            className={`shadow-none me-2`}
                                                            size={'md'}
                                                            onClick={() => setIsOpenTransfer(prev => !prev)}
                                                        >
                                                            <BsTelephoneForwardFill />
                                                        </Button>
                                                    }
                                                    <HangUpBtn />
                                                </Flex>
                                                <hr className='my-2' />
                                            </Card.Body>
                                        }
                                        {
                                            isIncoming &&
                                            <Card.Body className='px-3 py-2'>
                                                <Flex alignItems={'center'}>
                                                    <div className='flex-grow-1'>
                                                        {
                                                            timerincall &&
                                                            <div className='fs--1 mb-1'>
                                                                <Timer />
                                                            </div>
                                                        }
                                                        <h5> Входящий звонок </h5>
                                                        {
                                                            mtelsip &&
                                                            <h5 className='fs-1 text-600'>
                                                                {FormatPhone(mtelsip)}
                                                            </h5>
                                                        }
                                                        {
                                                            outtel ?
                                                                <h5 className='fs-1 text-500 ' >
                                                                    <span className='fs--1 text-dark'>На номер:</span> {FormatPhone(outtel)}
                                                                </h5>
                                                                :
                                                                <h5 className='fs-1 text-600' > {'не определен'} </h5>
                                                        }
                                                        {/* */}
                                                    </div>
                                                    <AnswerBtn className='me-1' />
                                                    {
                                                        iamincall &&
                                                        <Button
                                                            variant="info"
                                                            className={`shadow-none me-2`}
                                                            size={'md'}
                                                            onClick={() => setIsOpenTransfer(prev => !prev)}
                                                        >
                                                            <BsTelephoneForwardFill />
                                                        </Button>
                                                    }
                                                    <HangUpBtn />
                                                </Flex>
                                                <hr className='my-2' />
                                            </Card.Body>
                                        }
                                        {
                                            (!isIncoming && !isOutgoing) &&
                                            <Card.Body className='px-3 position-relative' >
                                                <Flex alignItems={'center'}>
                                                    <Dropdown className='me-2'>
                                                        <Dropdown.Toggle
                                                            bsPrefix='toggle'
                                                            variant='link'
                                                            size='sm'
                                                            className='px-0 py-0 text-900 unfocus'
                                                        >
                                                            <MdOutlinePhonelinkSetup size={29} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className='py-0'>
                                                            {
                                                                Object.keys(phoneInputTypes).map((key, index) => {
                                                                    const item = phoneInputTypes[key]
                                                                    return (
                                                                        <Dropdown.Item
                                                                            className='py-2'
                                                                            key={index}
                                                                            disabled={phoneInputTypes[key].value === inputType.value}
                                                                            onClick={() => {
                                                                                if (phoneInputTypes[key].value !== inputType.value) {
                                                                                    setInputType(phoneInputTypes[key])
                                                                                    setPhoneInput('')
                                                                                }
                                                                            }}
                                                                        >
                                                                            {item.label}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <InputMask
                                                        type='tel'
                                                        className={`form-control form-control-sm me-1 `}
                                                        mask={inputType.mask}
                                                        autoComplete="off"
                                                        placeholder={inputType.placeholder}
                                                        value={phoneInput}
                                                        onChange={
                                                            (e) => setPhoneInput(e.target.value)
                                                        }
                                                    />
                                                    <Button
                                                        size='sm'
                                                        variant='success'
                                                        disabled={phoneInput.replaceAll(/[^\d]/g, '').length !== inputType.length}
                                                        onClick={() => handleCall(phoneInput)}
                                                    >
                                                        <FontAwesomeIcon icon={'phone'} size='sm' />
                                                    </Button>
                                                </Flex>
                                            </Card.Body>
                                        }
                                    </>
                                    :
                                    <FalconCardHeader
                                        className="  py-2 px-3 "
                                        title={`Вы сейчас ${isSip ? 'онлайн' : 'офлайн'}`}
                                        titleTag="h6"
                                        // light={false}
                                        endEl={
                                            <Form.Check
                                                type="switch"
                                                id="customSwitchHeader"
                                                name="customSwitchHeaderName"
                                                checked={isSip}
                                                onChange={() => {
                                                    isSip ? handleDisconnectSip() : handleConnectSip()
                                                }}
                                            />
                                        }
                                    />
                            }

                            <Card.Body className='px-3 pt-0 pb-2 mt-2'>
                                <Flex alignItems={'center'} justifyContent={'between'} className='position-relative'>
                                    <div>
                                        {
                                            isSip &&
                                            <>
                                                <TooltipItem
                                                    tooltipText={'Клавитура'}
                                                    placement={'bottom'}
                                                >
                                                    <ToggleBtn
                                                        className={'me-1'}
                                                        isOpen={isOpenKeyboard}
                                                        handleToggle={() => setIsOpenKeyboard(prev => !prev)}
                                                        icon={faKeyboard}
                                                        disabled={!iamincall}
                                                    />
                                                </TooltipItem>

                                                <MuteBtn />

                                                <HoldBtn />
                                            </>
                                        }

                                    </div>
                                    <div>
                                        {
                                            isSip && <PauseBtn />
                                        }
                                        <TooltipItem
                                            tooltipText={'Рингтон'}
                                            placement={'bottom'}
                                        >
                                            <ToggleBtn
                                                className={'me-1'}
                                                isOpen={isOpenRingtone}
                                                handleToggle={() => setIsOpenRingtone(prev => !prev)}
                                                icon={faMusic}
                                            />
                                        </TooltipItem>
                                        <TooltipItem
                                            tooltipText={'История звонков'}
                                            placement={'bottom'}
                                            id={'siphistory'}
                                        >
                                            <ToggleBtn
                                                isOpen={isOpenHistory}
                                                handleToggle={() => setIsOpenHistory(prev => !prev)}
                                                icon={faHistory}
                                            />
                                        </TooltipItem>
                                    </div>
                                </Flex>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
                {
                    isOpenKeyboard &&
                    <Keyboard
                        keyboardArray={keyboardArray}
                        handleKey={handleKeyboardClick}
                        handleClose={() => setIsOpenKeyboard(prev => !prev)}
                    />
                }
                {
                    isOpenTransfer && iamincall &&
                    <TransferBlock
                        isMobile={false}
                        handleClose={() => setIsOpenTransfer(prev => !prev)}
                    />
                }
                {
                    isOpenRingtone && !iamincall &&
                    <RingtonesSelect
                        handleClose={() => setIsOpenRingtone(prev => !prev)}
                    />
                }
                {
                    (isIncoming || isOutgoing) &&
                    <PhoneInfoList />
                }
                {
                    isOpenHistory &&
                    <CallHistory
                        handleClose={() => setIsOpenHistory(prev => !prev)}
                    />
                }
            </div>
        </Draggable >
    )
}

export default SipBoxDesktop