import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import 'assets/scss/chunks/common.scss'
// import { getItemFromStore, setItemToStore } from 'helpers/utils';
// import { v4 as uuid } from 'uuid'

const App = () => {
  // useEffect(() => {
  //   const currentTabId = uuid()
  //   setItemToStore('current-tab', currentTabId) 
  //   const sipTab = getItemFromStore('sip-tab')
    
  //   return () => {
  //     if (sipTab === currentTabId) setItemToStore('sip-tab', '')
  //   };
  // }, []);
  // тут не работает только удаление при закрытии

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
