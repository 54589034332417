import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { renderTime } from 'helpers/utils';

const Timer = () => {

    const timerRef = useRef(null)

    const { timerincall } = useSelector(state => state.sip);

    const [timercall, setTimercall] = useState(0);

    useEffect(() => {
        clearInterval(timerRef.current)
        if (timerincall) {
            timerRef.current = setInterval(() => { 
                setTimercall(prev => prev + 1)
            }, [1000]);
        }
        else { 
            setTimercall(0)
            setIsOpenTransfer(false)
        }
    }, [timerincall])

  return (
     renderTime(timercall)
  )
}

export default Timer