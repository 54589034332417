import React, { Suspense, useContext, useEffect } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import { getIsAuth } from 'helpers/utils'; 
import AppStateLayout from './AppStateLayout'
import { useSelector } from 'react-redux';
import SipBoxLayout from 'components/sip/SipBoxLayout';
import { Helmet } from 'react-helmet';
import { MAIN_TITLE } from 'metatitle/title';
import Loader from 'components/common/Loader';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat'); 

  const isAuth = getIsAuth()

  const appState = useSelector(state => state.appState)
  const { softphone } = appState


  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <Helmet>
        <title>
          {MAIN_TITLE}
        </title>
      </Helmet>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <div className={classNames('content d-flex flex-column', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}

          <Suspense fallback={
            <Loader />
          }>
            {isAuth ? <AppStateLayout> <Outlet /> </AppStateLayout> : <Navigate to="/auth/login" replace />}
            {!isKanban && <Footer />}
          </Suspense>
        </div>
      </ProductProvider>
      {
        softphone.num &&
        <SipBoxLayout softphone={softphone} />
      }
    </div>
  );
};

export default MainLayout;
