import React, { useRef, useState, useEffect, useContext } from 'react'

import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPauseCircle } from '@fortawesome/free-regular-svg-icons'

import FalconCardHeader from '../../common/FalconCardHeader'
import Flex from '../../common/Flex'

import { getItemFromStore, setItemToStore } from '../../../helpers/utils'
import { borderColors, ringtoneBase, storageRingtoneName } from './sipInitialBase'
import { toast } from 'react-toastify'
import IconButton from 'components/common/IconButton'
import AppContext from 'context/Context'

const RingtonesSelect = ({ handleClose, isMobile }) => {

    const audioRef = useRef(null)

    const {
        config: { isDark }
    } = useContext(AppContext);

    const [played, setPlayed] = useState('');
    const [selected, setSelected] = useState(0);

    const [audioState, setAudioState] = useState(null);

    useEffect(() => {
        const currentRingtone = getItemFromStore(storageRingtoneName)
        setSelected(currentRingtone || 1)
    }, []);

    const handlePlay = (item) => {
        setPlayed(item)
        const audio = new Audio(item.src)
        try {
            audio.play();
            setAudioState(audio)
        }
        catch { e => console.log(e) }
    }

    const handleStop = () => {
        if (audioState) {
            setPlayed('')
            audioState.pause()
        }
    }

    const handleSelectRingtone = (value) => {
        setItemToStore(storageRingtoneName, value)
        setSelected(value)
        toast('Рингтон будет обновлен после обновления страницы')
    }

    const MainContent = () => (
        <>
            {
                ringtoneBase.map((item, index) => (
                    <div key={item.id} className='px-2'  >
                        <Flex alignItems={'center'} justifyContent={'between'} className={isMobile ? 'py-3' : 'py-1'}>
                            <Flex
                                alignItems={'center'}
                                className={`
                                    ${selected === item.id ? 'text-primary' : ''}
                                    ${isMobile ? 'fs-1' : 'fs--1'}
                                `}
                            >
                                {
                                    played.id === item.id ?
                                        <IconButton
                                            variant='outline'
                                            className='me-2'
                                            color='falcon-default'
                                            icon={faPauseCircle}
                                            size={isMobile ? 'lg' : 'sm'}
                                            onClick={handleStop}
                                        />
                                        :
                                        <IconButton
                                            variant='outline'
                                            className='me-2'
                                            color='falcon-default'
                                            icon={'play'}
                                            size={isMobile ? 'lg' : 'sm'}
                                            onClick={() => {
                                                handlePlay(item)
                                            }}
                                        />
                                }
                                {item.title}
                            </Flex>
                            {
                                selected === item.id ?
                                    <h5
                                        className={`${isMobile ? 'fs-1' : 'fs--1'} mb-0 text-primary`}
                                    >
                                        Выбрано
                                    </h5>
                                    :
                                    <h5
                                        className={`${isMobile ? 'fs-1' : 'fs--1'} mb-0 cursor-pointer`}
                                        onClick={() => handleSelectRingtone(item.id)}
                                    >
                                        Выбрать
                                    </h5>
                            }
                        </Flex>
                        {
                            index !== ringtoneBase.length - 1 &&
                            <hr className='m-0 text-300' />
                        }
                    </div>
                ))
            }
            <audio ref={audioRef} onEnded={() => setPlayed('')} />
        </>
    )

    return (
        isMobile ?
            <MainContent />
            :
            <div className='jssipModal-container mt-2'
                style={{
                    borderColor: isDark ? borderColors.dark : borderColors.light,
                }}
            >
                <Card >
                    <FalconCardHeader
                        title={"Рингтоны"}
                        titleClass={'fs--1'}
                        className="card-footer py-2 px-3"
                        light={true}
                        endEl={
                            <div className='cursor-pointer' onClick={handleClose}>
                                <FontAwesomeIcon icon={'times'} />
                            </div>
                        }
                    />
                    <Card.Body className='p-0'>
                        <MainContent />
                    </Card.Body>
                </Card>
            </div>
    )
}

export default RingtonesSelect