export const version = '1.0.4';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const hostingApp = process.env.REACT_APP_HOST 
export const hostingApi = process.env.REACT_APP_HOST_API
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isAuth: false,  
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
};

export const phoneMask = '+7 999 999 99 99'

export const redirectStore = 'falcon_redirect_from'

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
