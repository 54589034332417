import React, { useContext } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { getItemFromStore } from 'helpers/utils'
import { faKeyboard } from '@fortawesome/free-regular-svg-icons'
import AppContext from 'context/Context'

const MobileKeyboardBtn = ({ isOpen, handleToggle, disabled = false }) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    return (
        <Button
            variant={isOpen ? 'outline-danger' : "outline-primary"}
            className={classNames('py-3  shadow-none flex-grow-1 text-center', { "bg-200": !isDark })}
            size={'lg'}
            onClick={handleToggle}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={isOpen ? faTimes : faKeyboard} size='sm' />
        </Button>
    )
}

export default MobileKeyboardBtn