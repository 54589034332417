import * as actions from './actionTypes';

const initialState = {
    staff_id: 0,
    is_auth: false,
    main: {},

    staff: {},
    client: {},
    aspirant: {},
    softphone: { }
} 

export default function appStateReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_APP_STATE:
            return action.payload 
        default:
            return state;
    }
}  