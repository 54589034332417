import React, { useRef, memo } from 'react'
import JsSIP from 'jssip'
import SipBox from './view/SipBox'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setIamincall, setIsHold, setIsIncoming, setIsMute, setIsOutgoing, setIsReg, setIsSip, setLocalStream, setMtelsip, setOuttel, setScheme, setSession, setSipLoading, setTimerincall } from 'store/sip/actions'

import ringtonBackAudio from '../../assets/sound/call-end.mp3'
import beeps from '../../assets/sound/gudok.mp3'
import startCall from '../../assets/sound/success.mp3'
import { ringtoneBase, storageRingtoneName } from './view/sipInitialBase'
import { getItemFromStore, setItemToStore } from '../../helpers/utils'
import { setInStat } from './in-stat/SetInStat'
import { CONFIRMED_CALL, CONNECTING_CALL, ENDEDED_CALL, FAILED_CANCELED_CALL, FAILED_CALL, MUTED_CALL, PROGRESS_CALL, PROGRESS_INCOMING, PROGRESS_OUTGOING, UNMUTED_CALL } from './in-stat/types'


const SipBoxLayout = memo(function SipBoxLayout(props) {

    toast('Успешная инициализация телефонии')

    const { softphone } = props

    // useEffect(() => {
    //     const currentTabId = getItemFromStore('current-tab')
    //     const sipTab = getItemFromStore('sip-tab')
    //     if (!sipTab) {
    //         setItemToStore('sip-tab', currentTabId)
    //     }
    //     return () => {
    //         if(sipTab === currentTabId) setItemToStore('sip-tab', '')
    //     };
    // }, []);

    const remoteAudio = useRef(null)
    const localeAudio = useRef(null)
    const ringtone = useRef(null)
    const sounds = useRef(null)

    const ringtoneId = getItemFromStore(storageRingtoneName) || 1

    const currentRingtone = ringtoneBase.find(item => item.id === ringtoneId)

    const dispatch = useDispatch()


    const socket = new JsSIP.WebSocketInterface(`wss://${softphone.host}:${softphone.port_http}/telefonia055/ws`)
    // socket.via_transport = 'udp'
    // wss, ws, tcp, udp 5060, для tls 5061 

    const configuration = {
        sockets: [socket],
        uri: `sip:${softphone?.num}@${softphone.host}:${softphone?.port_sip}`, // ${softphone?.port_sip}
        password: softphone?.pass,
        register: false,
        display_name: String(softphone?.num),
        realm: `asterisk`,
        contact_uri: `sip:${softphone?.num}@${softphone.host}:${softphone?.port_sip}`,
        no_answer_timeout: 60,
        connection_recovery_min_interval: 3,
        connection_recovery_max_interval: 10,
        use_preloaded_route: true,
        register_expires: 180
    };
    const ua = new JsSIP.UA(configuration)


    // JsSIP.debug.enable('JsSIP:*');
    JsSIP.debug.disable('JsSIP:*');

    ua.on('connecting', function (e) {
        toast.success("Подключение ...")
    });
    ua.on('connected', function (e) {
        toast.success("SIP подключен")
        dispatch(setIsSip(true))
        dispatch(setSipLoading(false))
    })
    ua.on('disconnected', function (e) {
        toast.error("SIP disconnected")
        dispatch(setIsSip(false))
        dispatch(setSipLoading(false))
    })
    ua.on('registrationFailed', function (e) {
        toast.error("SIP - " + e.cause)
        dispatch(setSipLoading(false))

    });
    ua.on('registered', function (e) {
        toast.success('Регистрация установлена')
        dispatch(setIsReg(true))
    });
    ua.on('unregistered', function (e) {
        toast.error('Регистрация отменена')
        dispatch(setIsReg(false))
    });

    ua.on('newRTCSession', function (data) {

        console.log("NEW RTC SESSION")
        var session = data.session
        let peerconnectionLocal = data.session.connection

        dispatch(setSession(data.session))

        session.on("confirmed", function (e) {
            console.log('confirmed', e)
            toast.success('Звонок принят!')
            dispatch(setTimerincall(true))
            // setTimerincall(true);
            // ringbacktone.pause();
            ringtone.current.pause();
            ringtone.current.currentTime = 0.0;

            sounds.current.src = startCall
            sounds.current.currentTime = 0.0
            sounds.current.play()

            dispatch(setIamincall(true))

            // const sessionNumbers = getSessionNumbers(session) 
            // console.log('confirmed' , sessionNumbers )
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: CONFIRMED_CALL
            })

        });
        session.on("ended", function (e) {
            console.log('ended', e)
            sounds.current.currentTime = 0
            sounds.current.src = ringtonBackAudio
            sounds.current.play()

            if (session.direction == "outgoing") {
                JsSIP.Utils.closeMediaStream(this._localClonedStream);
                // dispatch(setOuttel(0))
                // dispatch(setMtelsip(0))
            }

            peerconnectionLocal = null;
            dispatch(setLocalStream(null))
            dispatch(setSession(null))
            dispatch(setTimerincall(false))
            dispatch(setIamincall(false))
            dispatch(setIsIncoming(false))
            dispatch(setIsOutgoing(false))
            dispatch(setIsMute(false))
            dispatch(setIsHold(false))

            dispatch(setScheme(0))

            dispatch(setOuttel(0))
            dispatch(setMtelsip(0))

            ringtone.current.pause();
            ringtone.current.currentTime = 0.0;

            // const sessionNumbers = getSessionNumbers(session) 
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: ENDEDED_CALL
            })

        });
        session.on("failed", function (e) {
            console.log('failed', e)
            toast.error('Звонок failed')
            if (session.direction == "outgoing") {
                JsSIP.Utils.closeMediaStream(this._localClonedStream);
                dispatch(setOuttel(0))
            }

            dispatch(setOuttel(0))
            dispatch(setMtelsip(0))

            dispatch(setLocalStream(null))
            dispatch(setSession(null))

            session = null; // это не нужно? 
            peerconnectionLocal = null; // это не нужно? 

            dispatch(setTimerincall(false))
            dispatch(setIamincall(false))
            dispatch(setIsIncoming(false))
            dispatch(setIsOutgoing(false))
            dispatch(setIsMute(false))
            dispatch(setIsHold(false))

            dispatch(setScheme(0))

            sounds.current.pause();
            ringtone.current.pause();
            ringtone.current.currentTime = 0.0;

            const sessionNumbers = getSessionNumbers(session)


            if (e.cause == "Canceled" || e.cause == "Rejected") {
                setInStat({
                    out_tel: sessionNumbers.outtel,
                    phone: sessionNumbers.mtelsip,
                    tip: FAILED_CANCELED_CALL
                })
            } else {
                setInStat({
                    out_tel: sessionNumbers.outtel,
                    phone: sessionNumbers.mtelsip,
                    tip: FAILED_CALL
                })
            }
        });
        session.on("progress", function (e) {
            console.log('progress', e)
            // const toProp = e.request.to._uri._user || 0
            // const fromProp = e.request.from._display_name || 0 

            if (session.direction == "incoming") { // && !iamincall

                dispatch(setIsIncoming(true))
                dispatch(setIsOutgoing(false))
                // setHeaderOpen(true)

                const sessionNumbers = getSessionNumbers(session)
                dispatch(setMtelsip(sessionNumbers.mtelsip))
                dispatch(setOuttel(sessionNumbers.outtel))

                // const mtelsipData = session._request.headers.From[0].parsed._uri._user
                // dispatch(setMtelsip(mtelsipData))
                // if (session._request.headers.Diversion) { 
                //     dispatch(setOuttel(session._request.headers.Diversion[0].raw))
                // } else { 
                //     dispatch(setOuttel(session._request.headers.From[0].parsed._display_name))
                // }
                // if (mtelsipData.length != 6) {
                //     const sliced = ("+" + mtelsipData).slice(-12)
                //     dispatch(setMtelsip(sliced))
                // }

                ringtone.current.play();

                setInStat({
                    out_tel: 0,
                    phone: 0,
                    tip: PROGRESS_INCOMING
                })
            }
            if (session.direction == "outgoing") {
                toast.success('Вызов в процессе')

                // гудки нужно еще отрегулировать когда включать, когда выключать 
                // sounds.current.src = beeps
                // sounds.current.currentTime = 0.0
                // sounds.current.play()

                //ringbacktone.play();
                //slajx(window.location.hash,"/data/php/sip/outtel.php");
                dispatch(setOuttel(0));
                dispatch(setIsIncoming(false))
                dispatch(setIsOutgoing(true))


                // const sessionNumbers = getSessionNumbers(session)
                setInStat({
                    out_tel: 0,
                    phone: 0,
                    tip: PROGRESS_OUTGOING
                })

            }
            // const sessionNumbers = getSessionNumbers(session)
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: PROGRESS_CALL
            })
        });
        session.on('connecting', function (e) {
            toast.success("Подключение");
            dispatch(setSipLoading(false))

            const toProp = e.request.to._uri._user
            const fromProp = e.request.from._display_name

            console.log('connecting to', toProp)
            console.log('connecting from', fromProp)

            dispatch(setOuttel(fromProp))
            dispatch(setMtelsip(toProp))


            sounds.current.pause()

            if (session.direction == "outgoing") {

                dispatch(setIsIncoming(false))
                dispatch(setIsOutgoing(true))

                toast.success("Исходящий");
                // localStream = peerconnectionLocal.getLocalStreams()[0];
                const stream = peerconnectionLocal.getLocalStreams()[0];
                dispatch(setLocalStream(stream))
                if (stream) { // это работает
                    const clone = stream.clone()
                    this._localClonedStream = clone
                    localeAudio.current.srcObject = clone;
                }
                peerconnectionLocal.addEventListener('addstream', (event) => { // вот тут не работает
                    console.log('add stream')
                    toast.success("Поток на выход налажен");
                    remoteAudio.current.srcObject = event.stream;
                });
                dispatch(setOuttel(0));

            } else {
                dispatch(setSipLoading(false))
                dispatch(setIsIncoming(true))
                dispatch(setIsOutgoing(false))
            }

            // const sessionNumbers = getSessionNumbers(session)
            setInStat({
                out_tel: fromProp,
                phone: toProp,
                tip: CONNECTING_CALL
            })
        });
        session.on('peerconnection', function (data) {
            console.log('peerconnection')

            data.peerconnection.addEventListener('addstream', (event) => {
                console.log(event)
                toast.success("Поток на вход налажен");
                remoteAudio.current.srcObject = event.stream;
                remoteAudio.current.play();
            });
        });
        session.on('muted', function () {
            dispatch(setIsMute(true))
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: MUTED_CALL
            })
        });
        session.on('unmuted', function () {
            dispatch(setIsMute(false))
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: MUTED_CALL
            })
        });
    });


    const getSessionNumbers = (sessionProp) => {
        let mtelsipData = 0
        let outtelData = 0
        if (!sessionProp) return {
            mtelsip: 0,
            outtel: 0
        }
        // if (sessionProp._request.headers.Diversion) {
        //     outtelData = sessionProp._request.headers.Diversion[0].raw
        // } else {
        //     outtelData = sessionProp._request.headers.From[0].parsed?._display_name
        // }
        // if (sessionProp._request.headers.From[0].parsed._uri._user) {
        //     mtelsipData = (sessionProp._request.headers.From[0].parsed._uri._user).slice(-12)
        // }
        // else {
        //     mtelsipData = sessionProp._request.headers.From[0].parsed._uri._user
        // }
        if (sessionProp._request.headers.From[0]?.parsed?._display_name) {
            outtelData = sessionProp._request.headers.From[0].parsed?._display_name
        }
        else if (sessionProp._request.from._display_name) {
            outtelData = sessionProp._request.from._display_name
        }
        if (sessionProp._request.headers.From[0].parsed._uri._user) {
            mtelsipData = sessionProp._request.headers.From[0].parsed._uri._user
        }
        else if (sessionProp._request.to._uri._user) {
            mtelsipData = sessionProp._request.to._uri._user
        }

        const result = {
            mtelsip: mtelsipData ? Number(mtelsipData) : 0,
            outtel: outtelData ? Number(outtelData) : 0
        }

        return result
    }
    return (
        <>
            <audio ref={remoteAudio} autoPlay className='remote' />
            <audio ref={localeAudio} autoPlay className='locale' />
            <audio ref={ringtone} src={currentRingtone.src} loop={true} />
            <audio ref={sounds} />
            <SipBox ua={ua} />
        </>
    )
})

export default SipBoxLayout