export const CONFIRMED_CALL = 'CONFIRMED_CALL'
export const ENDEDED_CALL = 'ENDEDED_CALL'
export const FAILED_CALL = 'FAILED_CALL'
export const FAILED_CANCELED_CALL = 'FAILED_CANCELED_CALL'
export const PROGRESS_CALL = 'PROGRESS_CALL'
export const PROGRESS_INCOMING = 'PROGRESS_INCOMING'
export const PROGRESS_OUTGOING = 'PROGRESS_OUTGOING'
export const CONNECTING_CALL= 'CONNECTING_CALL'
export const MUTED_CALL= 'MUTED_CALL'
export const UNMUTED_CALL= 'UNMUTED_CALL'
export const PAUSE_ON = 'PAUSE_ON'
export const PAUSE_PERM_ON = 'PAUSE_PERM_ON'
export const PAUSE_OFF = 'PAUSE_OFF'
export const CALL_FROM_MODAL = 'CALL_FROM_MODAL'
export const CALL_FROM_ASPIRANT = 'CALL_FROM_ASPIRANT' // 1 схема
export const CALL_FROM_INTERVIEW = 'CALL_FROM_INTERVIEW' // 1 схема
export const CREATE_CALL_FROM_ASPIRANT = 'CREATE_CALL_FROM_ASPIRANT' // 2 схема
export const CREATE_CALL_FROM_INTERVIEW = 'CREATE_CALL_FROM_INTERVIEW' // 2 схема
export const SIP_ON = 'SIP_ON'
export const SIP_OFF = 'SIP_OFF'
export const REG_ON = 'REG_ON'
export const REG_OFF = 'REG_OFF'
export const COPY_INTERVIEW_LIST = 'COPY_INTERVIEW_LIST'
export const CALL_INTERVIEW_LIST = 'CALL_INTERVIEW_LIST'