import React, { useContext, useEffect, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSplitLayout from './AuthSplitLayout';
import bgImg from 'assets/img/generic/14.jpg';
import is from 'is_js';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import AppContext from 'context/Context';
import StoreProviderLayout from './StoreProviderLayout';

const Error404 = lazy(() => import('components/errors/Error404'));
const Error500 = lazy(() => import('components/errors/Error500'));
const LoginForm = lazy(() => import('components/authentication/LoginForm'));
const LogoutContent = lazy(() => import('components/authentication/LogoutContent'));
const ApplicantCreate = lazy(() => import('../components/pages/pageUser/pageApplicant/create/ApplicantCreate'));
const AspirantProvider = lazy(() => import('components/pages/userList/aspirant/AspirantProvider'));
const AspirantPage = lazy(() => import('components/pages/pageUser/pageApplicant/page/AspirantPage'));
const ApplicantDetailsEditor = lazy(() => import('components/pages/pageUser/pageApplicant/edit/ApplicantDetailsEditor'));
const EditContact = lazy(() => import('components/pages/pageUser/common/contacts/edit/EditContact'));
const StaffPage = lazy(() => import('components/pages/pageUser/pageStaff/page/StaffPage'));
const CreateInterview = lazy(() => import('components/pages/interviewBase/create/CreateInterview'));
const OnceInterview = lazy(() => import('components/pages/interviewBase/onceInterview/OnceInterview'));
const EditInterview = lazy(() => import('components/pages/interviewBase/edit/EditInterview'));
const InterviewProvider = lazy(() => import('components/pages/interviewBase/list/InterviewProvider'));

const ReportsHrTotal = lazy(() => import('components/pages/reports/hr/Total'));
const ReportsHrStatus = lazy(() => import('components/pages/reports/hr/Status'));
const ReportsHrPersons = lazy(() => import('components/pages/reports/hr/Persons'));
const SipNumbersList = lazy(() => import('components/pages/settingPages/numbersList/SipNumbersList'));
const SiteList = lazy(() => import('components/pages/siteList/list/SiteList'));
const SiteListAdd = lazy(() => import('components/pages/siteList/add/SiteListAdd'));
const EditSite = lazy(() => import('components/pages/siteList/edit/EditSite'));
const SiteMap = lazy(() => import('components/pages/siteList/map/SiteMap'));

const SelectsEditor = lazy(() => import('components/pages/settingPages/selects/SelectsEditor'));
const ScriptsCall = lazy(() => import('components/pages/settingPages/scripts-call/ScriptsCall'));
const CdrReportProvider = lazy(() => import('components/pages/reports/cdr/CdrReportProvider'));
const MainPage = lazy(() => import('components/pages/mainPage/MainPage'));
const SelectsFaq = lazy(() => import('components/pages/settingPages/faq/selects/SelectsFaq'));
const SoftphoneFaq = lazy(() => import('components/pages/settingPages/faq/softphone/SoftphoneFaq'));
const QuectionsFaq = lazy(() => import('components/pages/settingPages/faq/quections/QuectionsFaq'));

const Layout = () => {

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* MainLayout */}

        <Route element={<StoreProviderLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<MainPage />} />

          {/* Aspirants */}
          <Route path="/aspirant-create/:type" element={<ApplicantCreate />} />
          <Route path="/users/aspirant/:name" element={<AspirantProvider type={'aspirant'} />} />
          <Route path="/users/aspirant/:name/:page" element={<AspirantProvider type={'aspirant'} />} />

          <Route path="/user/aspirant/:type/:id" element={<AspirantPage />} />

          <Route exact path="/user/staff/:id" element={<StaffPage />} />

          {/* редактирование пользователя */}
          <Route exact path="/edit-user/founder/:id/" element={<ApplicantDetailsEditor type={'founder'} />} />
          <Route exact path="/edit-user/pupils/:id/" element={<ApplicantDetailsEditor type={'pupils'} />} />

          {/* редактирование контактов */}
          <Route exact path="/edit-contacts/:type/:id/" element={<EditContact />} />

          {/* Interviews */}
          <Route exact path="/interview-create/" element={<CreateInterview />} />
          <Route exact path="/interview/:id" element={<OnceInterview />} />
          <Route exact path="/interview/:id/edit" element={<EditInterview />} />
          <Route exact path="/interview-base/:pageProp" element={<InterviewProvider />} />
          <Route exact path="/interview-base/" element={<InterviewProvider />} />

          {/* REPORTS */}
          <Route exact path="/report/hrt" element={<ReportsHrTotal />} />
          <Route exact path="/report/hrs" element={<ReportsHrStatus />} />
          <Route exact path="/report/hrp" element={<ReportsHrPersons />} />
          <Route exact path="/report/cdr" element={<CdrReportProvider />} />
          <Route exact path="/report/cdr/:pageProp" element={<CdrReportProvider />} />

          {/* SETTINGS */}
          <Route exact path="/settings/numbers" element={<SipNumbersList />} />
          <Route exact path="/settings/selects" element={<SelectsEditor />} />
          <Route exact path="/settings/script-call" element={<ScriptsCall />} />

          {/* FAQ */}
          <Route exact path="/faq/selects" element={<SelectsFaq />} />
          <Route exact path="/faq/softphone" element={<SoftphoneFaq />} />
          <Route exact path="/faq/quections" element={<QuectionsFaq />} />

          {/* INTEGRATION */}
          <Route exact path="/site-list" element={<SiteList />} />
          <Route exact path="/site-list/:pageProp" element={<SiteList />} />
          <Route exact path="/site-list-add" element={<SiteListAdd />} />
          <Route exact path="/site-edit/:id" element={<EditSite />} />
          <Route exact path="/site-map" element={<SiteMap />} />

          <Route path="*" element={<Error404 />} />

        </Route>

        {/*- ------------- Authentication ---------------------------  */}
        <Route element={<AuthSplitLayout bgProps={{ image: bgImg, position: '50% 30%' }} />}>
          <Route path={`auth/login`} element={<LoginForm />} />
          <Route path={`auth/logout`} element={<LogoutContent />} />
        </Route>


        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />

      </Routes>
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
