
import React, { useRef, useEffect, useContext } from 'react'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dropdown } from 'react-bootstrap'
import { setIsPause, setIsPausePerm, setIsPauseTimer } from 'store/sip/actions'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { pauseDuration } from '../view/sipInitialBase'
import api from 'api/api'
import { setInStat } from '../in-stat/SetInStat'
import { PAUSE_OFF, PAUSE_ON, PAUSE_PERM_ON } from '../in-stat/types'
import AppContext from 'context/Context'

const PauseBtn = ({ isMobile }) => {

    const pauseTimerRef = useRef(null)

    const {
        config: { isDark }
    } = useContext(AppContext);

    const dispatch = useDispatch()
    const { isPause, isPauseTimer, isPausePerm } = useSelector(state => state.sip);


    useEffect(() => {
        if (isPauseTimer) {
            clearTimeout(pauseTimerRef.current)
            pauseTimerRef.current = setTimeout(() => {
                dispatch(setIsPause(false))
                dispatch(setIsPauseTimer(false))
                toast.success('Вы вернулись в очередь')

                api.post(`integration/ami/pause`, {
                    in_pause: false
                })
            }, pauseDuration * 1000);
        }
    }, [isPauseTimer])

    useEffect(() => {
        api.post(`integration/ami/pause`, {
            in_pause: false
        })
            .catch(err => console.log(err))
    }, []);

    const handleTimerPause = () => {
        dispatch(setIsPause(true))
        dispatch(setIsPauseTimer(true))
        toast.warn(`Вы вышли из очереди на ${pauseDuration} секунд`)
        api.post(`integration/ami/pause`, {
            in_pause: true
        })
        setInStat({
            out_tel: 0,
            phone: 0,
            tip: PAUSE_ON
        })
    }

    const handlePermanentPause = () => {
        clearTimeout(pauseTimerRef.current)
        dispatch(setIsPause(true))
        dispatch(setIsPausePerm(true))
        toast.warn('Вы вышли из очереди')
        api.post(`integration/ami/pause`, {
            in_pause: true
        })
        setInStat({
            out_tel: 0,
            phone: 0,
            tip: PAUSE_PERM_ON
        })
    }

    const handleResetPause = () => {
        dispatch(setIsPause(false))
        dispatch(setIsPauseTimer(false))
        dispatch(setIsPausePerm(false))
        toast.success('Вы вернулись в очередь')
        api.post(`integration/ami/pause`, {
            in_pause: false
        })
        setInStat({
            out_tel: 0,
            phone: 0,
            tip: PAUSE_OFF
        })
    }

    return (
        isMobile ?
            <>
                {
                    isPause ?
                        <>
                            <Button
                                variant='outline-warning'
                                size='lg'
                                className='w-100 mb-3'
                                onClick={handleResetPause}
                            >
                                Вернуться в очередь
                            </Button>
                        </>
                        :
                        <>
                            <Button
                                variant='outline-warning'
                                size='lg'
                                className='w-100 mb-3'
                                onClick={handleTimerPause}
                            >
                                <FontAwesomeIcon icon={faPause} className='me-2' />
                                Пауза на {pauseDuration} секунд
                            </Button>
                            <Button
                                variant='outline-warning'
                                size='lg'
                                className='w-100 mb-3'
                                onClick={handlePermanentPause}
                            >
                                <FontAwesomeIcon icon={faPause} className='me-2' />
                                Постоянная пауза
                            </Button>
                        </>
                }
            </>
            :
            <Dropdown className="no-drag d-inline-block">
                <Dropdown.Toggle
                    bsPrefix='toggle'
                    variant={isPause ? "falcon-warning" : 'falcon-primary'}
                    className={classNames('px-2 shadow-none me-1', { "bg-200": !isDark })}
                    size="sm"
                >
                    {/* <Button
                        variant={isPause ? "falcon-warning" : 'falcon-primary'}
                        className={classNames('px-2 shadow-none', { "bg-200": !isDark })}
                        size='sm'
                    > */}
                    <FontAwesomeIcon icon={faPause} />
                    {/* </Button> */}
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-1'>
                    {
                        isPause &&
                        <>
                            <Dropdown.Item onClick={handleResetPause}>
                                Вернуться в очередь
                            </Dropdown.Item>
                            <Dropdown.Divider />
                        </>
                    }
                    <Dropdown.Item disabled={isPause} onClick={handleTimerPause} className={isPauseTimer ? 'text-warning' : ''} >
                        {
                            isPauseTimer &&
                            <FontAwesomeIcon icon={'check'} className='me-1' />
                        }
                        {pauseDuration} секунд
                    </Dropdown.Item>
                    <Dropdown.Item disabled={isPause} onClick={handlePermanentPause} className={isPausePerm ? 'text-warning' : ''} >
                        {
                            isPausePerm &&
                            <FontAwesomeIcon icon={'check'} className='me-1' />
                        }
                        Постоянная пауза
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
    )
}

export default PauseBtn