import React, { useContext } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import AppContext from 'context/Context'

const ToggleBtn = ({ isOpen, handleToggle, icon, disabled = false, isMobile, className = '' }) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    return (
        <Button
            variant={isOpen ? 'falcon-danger' : "falcon-primary"}
            className={classNames(`px-2 shadow-none ${className}`, { "bg-200": !isDark, 'flex-grow-1 text-center': isMobile })}
            size={isMobile ? 'lg' : 'sm'}
            onClick={handleToggle}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={isOpen ? faTimes : icon} size='sm' />
        </Button>
    )
}

export default ToggleBtn