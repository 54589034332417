
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { faTimes, faVolumeOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import AppContext from 'context/Context'

const MuteBtn = () => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { session, isMute, iamincall } = useSelector(state => state.sip);


    const toggleMute = () => {
        if (session) {
            if (session.isMuted()['audio']) {
                session.unmute();
            } else {
                session.mute();
            }
        }
    }

    return (
        <Button
            variant={isMute ? "warning" : "outline-primary"}
            className={classNames('flex-grow-1 py-3 text-nowrap shadow-none', { "bg-200": !isDark })}
            size='lg'
            onClick={() => {
                toggleMute()
            }}
            disabled={!iamincall}
        >
            <FontAwesomeIcon icon={faVolumeOff} size='sm' className='me-1' />
            <FontAwesomeIcon icon={faTimes} size='sm' />
        </Button>
    )
}

export default MuteBtn