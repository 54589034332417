import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap'

const HangUpBtn = ({ className = '', callback, isMobile }) => {

    const { session } = useSelector(state => state.sip);

    const handleHangUp = () => {
        if (session) {
            console.log('terminate', session)
            session.terminate();
        }
        if(callback) callback()
    }

    return (
        <Button
            variant="danger"
            className={`shadow-none ${className && className}`}
            size={isMobile ? 'lg' : 'md'}
            onClick={handleHangUp}
        >
            <FontAwesomeIcon icon={faPhoneSlash} size='sm' />
        </Button>
    )
}

export default HangUpBtn