import React, { useRef } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { v4 as uuid } from 'uuid'

const TooltipItem = ({ children, tooltipText, placement }) => {

  const id = uuid()

  const ref = useRef(null)

  return (
    <>
      <OverlayTrigger target={ref} placement={placement}
        overlay={
          <Tooltip id={'tooltip-' + id}>
            {tooltipText}
          </Tooltip>
        }
      >
        <div className='d-inline-block' ref={ref}>

          {children}
        </div>
      </OverlayTrigger>
    </>
  );
}

export default TooltipItem;
