import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/logo/logo.svg';

const Logo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left py-2': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex   align-items-center',
          {
            'align-items-sm-end py-3': at === 'navbar-vertical',
            'align-items-sm-end': at === 'navbar-top',
            'flex-center fw-bolder fs-5 my-2 position-relative': at === 'auth'
          },
          className
        )}
        style={{zIndex: at === 'auth' ? 1000 : 1}}
      >
        <img className="me-1 resp-logo-img" src={logo} alt="Logo" width="24px" height="24px" />
        <h4 className="fw-bold text-700 mb-0 lh-1 resp-logo-text"  >
          <span style={{ color: "#2c7be5" }}>Sai</span>
          CRM
        </h4>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
