import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/logo/logo.svg';
import logoWhite from 'assets/logo/logo-white.svg';
import AppContext from 'context/Context';

const Logo = ({ at, width, className, textClass, ...rest }) => {

  const { isDark } = useContext(AppContext);

  return ( 
      <div
        className={classNames(
          'd-flex flex-column flex-sm-row align-items-center',
          {
            'align-items-sm-end py-3': at === 'navbar-vertical',
            'align-items-sm-end': at === 'navbar-top',
            'flex-center fw-bolder fs-5 position-relative': at === 'auth'
          },
          className
        )}
        style={{zIndex: at === 'auth' ? 1000 : 1}}
      >
        <img className="me-sm-1 resp-logo-img" src={ isDark ? logo : logoWhite} alt="Logo" width="40px" height="40px" />
        <h4 className={`${isDark ? 'text-700' : 'text-200' } fw-bold mb-0 lh-1 resp-logo-text`}  >
          <span className={isDark ? 'text-primary' : 'text-light'}>Sai</span>
          CRM
        </h4>
      </div> 
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
