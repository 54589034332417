import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'react-bootstrap'
import { getItemFromStore, isIterableArray, ShowDateTime } from '../../../helpers/utils'
import FalconCardHeader from '../../common/FalconCardHeader'
import Flex from '../../common/Flex'
import { borderColors, storageCallHistoryName, textSipOffline } from './sipInitialBase'
import TooltipItem from 'components/common/TooltipItem';
import SoftBadge from 'components/common/SoftBadge';
import { BsArrowDownRightCircleFill, BsArrowUpRightCircleFill } from 'react-icons/bs'
import AppContext, { SipContext } from 'context/Context';
import { toast } from 'react-toastify';

const CallHistory = ({ handleClose, isMobile }) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { setPhoneInputContext } = useContext(SipContext);
    const { isSip } = useSelector(state => state.sip)


    const callHistory = getItemFromStore(storageCallHistoryName, [])

    const handleSelectNumber = (tel) => {
        if (isSip) setPhoneInputContext(tel)
        else toast.warn(textSipOffline)
    }

    const MainContent = () => (
        isIterableArray(callHistory) ?
            <Card.Body className='px-2 py-0 scrollbar' style={{ maxHeight: isMobile ? 300 : 170 }}>
                {
                    callHistory.map((item, index) => (
                        <div key={item.id}>
                            <Flex alignItems={'center'} justifyContent='between' className={isMobile ? 'py-3' : 'py-2'}>
                                <Flex alignItems={'center'}>
                                    <TooltipItem
                                        tooltipText={item.type === 'incoming' ? 'Входящий' : "Исходящий"}
                                        placement={'top'}
                                    >
                                        <>
                                            {
                                                item.type === 'incoming' ?
                                                    <BsArrowDownRightCircleFill size={isMobile ? 30 : 20} />
                                                    :
                                                    <BsArrowUpRightCircleFill size={isMobile ? 30 : 20} />
                                            }
                                            <FontAwesomeIcon
                                                icon={'phone'}
                                                className='ms-2'
                                                style={{ fontSize: isMobile ? 25 : 15 }}
                                            />
                                        </>
                                    </TooltipItem>
                                    <h6
                                        className={`mb-0 mx-2 hover-primary cursor-pointer ${isMobile ? 'fs-2' : 'fs--1'}`}
                                        onClick={() => handleSelectNumber(item.tel)}
                                    >
                                        {item.tel}
                                    </h6>
                                </Flex>
                                <SoftBadge
                                    bg='primary'
                                    pill
                                    className={`d-flex align-items-center ${isMobile ? 'fs-2' : 'fs--1'} ms-1`}
                                >
                                    {ShowDateTime(item.date)}
                                </SoftBadge>
                            </Flex>
                            {
                                index !== callHistory.length - 1 &&
                                <hr className='m-0 text-300' />
                            }
                        </div>
                    ))
                }
            </Card.Body>
            :
            <h5 className='text-center text-500 py-3'>Звонков нет</h5>
    )


    return (
        isMobile ?
            <MainContent />
            :
            <div className='jssipModal-container mt-2'
                style={{
                    borderColor: isDark ? borderColors.dark : borderColors.light,
                }}
            >
                <Card>
                    <FalconCardHeader
                        className="with-border py-2 px-3 "
                        titleClass={'fs--1'}
                        light={true}
                        title={callHistory?.length ? 'История звонков' : 'Звонков нет'}
                        endEl={
                            <div className=' cursor-pointer' onClick={handleClose}>
                                <FontAwesomeIcon icon={'times'} />
                            </div>
                        }
                    />
                    <MainContent />
                </Card>
            </div>
    )
}

export default CallHistory