import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DividerLeft = ({ className, textClassName = '', children, leftOffset = '.5rem' }) => (
  <div className={classNames('w-100 position-relative text-center', className)}>
    <hr className="text-300" />
    <div className={`divider-content-center ${textClassName}`}
      style={{
        left: leftOffset, 
        transform: 'translateX(0) translateY(-50%)'
      }}
    >{children}</div>
  </div>
);

DividerLeft.propTypes = {
  className: PropTypes.node,
  children: PropTypes.node
};

export default DividerLeft;
