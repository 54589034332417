import React from 'react'
import { Provider } from 'react-redux'
import { Outlet } from 'react-router-dom'
import store from '../store/store'
import MainLayout from './MainLayout'
import SipContextProvider from '../context/sipContext/SipContextProvider';

const StoreProviderLayout = () => {
    return (
        <Provider store={store}>
            <SipContextProvider>
                <MainLayout />
            </SipContextProvider>
        </Provider>
    )
}

export default StoreProviderLayout