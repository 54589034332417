
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap' 
import TooltipItem from '../../common/TooltipItem'
import classNames from 'classnames'
import { FiPhoneMissed } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setIsHold } from 'store/sip/actions'
import { FaTimes } from 'react-icons/fa'
import AppContext from 'context/Context'

const HoldBtn = () => {

    const {
        config: { isDark }
      } = useContext(AppContext);

    const { session, isHold, iamincall } = useSelector(state => state.sip);
    const dispatch = useDispatch()

    const toggleHold = () => {
        if (session) {
            if (session.isOnHold().local) {
                session.unhold();
                dispatch(setIsHold(false))
            } else {
                session.hold();
                dispatch(setIsHold(true))
            }
        }
    }

    return (

        <TooltipItem
            tooltipText={isHold ? 'Снять с удержания' : 'Поставить на удержание'}
            placement={'bottom'}
        >
            <Button
                variant={isHold ? "warning" : "falcon-warning"}
                className={classNames('ms-2 px-2 text-nowrap shadow-none', { "bg-200": !isDark && !isHold })}
                size='sm'
                onClick={() => {
                    toggleHold()
                }}
                disabled={!iamincall}
            >
                <FiPhoneMissed />
                {
                    isHold ? <FaTimes className='ms-1' /> : ''
                }
            </Button>
        </TooltipItem>
    )
}

export default HoldBtn