import React, { useState } from 'react'
import { SipContext } from '../Context'

const SipContextProvider = ({ children }) => {

    const [headerOpen, setHeaderOpen] = useState(false);
    const [phoneInputContext, setPhoneInputContext] = useState('');
    const [isShowPlayer, setIsShowPlayer] = useState(false);

    const value = {
        headerOpen, setHeaderOpen,
        phoneInputContext, setPhoneInputContext,
        isShowPlayer, setIsShowPlayer
    }

    return (
        <SipContext.Provider value={value}>
            {children}
        </SipContext.Provider>
    )

}

export default SipContextProvider