import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap'
import { sipoptions } from '../view/sipInitialBase';

const AnswerBtn = ({ className = '', isMobile }) => {

    const { session, iamincall } = useSelector(state => state.sip);

    const handleAnswer = () => {
        if (session) {
            session.answer(sipoptions);
        }
    }

    return (
        !iamincall ?
            <Button
                variant="success"
                className={`shadow-none ${className && className}`}
                size={isMobile ? 'lg' : 'md'}
                onClick={handleAnswer}
            >
                <FontAwesomeIcon icon={'phone'} size='sm' />
            </Button>
            : ''
    )
}

export default AnswerBtn