import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

const Loader = props => (
  <Row className={`flex-center py-2 ${props.className || ''}`}>
    <Col xs="auto" className='d-flex flex-center'>
      <Spinner {...props} style={{ animation: '1.5s linear infinite spinner-border', borderWidth: 1 }} />
    </Col>
  </Row>
);

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
  animation: 'border',
  size: 'lg',
  variant: 'default'
};

export default Loader;
