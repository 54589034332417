export const SET_IS_MUTE = 'SET_IS_MUTE';
export const SET_IS_SIP = 'SET_IS_SIP';
export const SET_IS_REG = 'SET_IS_REG';
export const SET_IS_CALL = 'SET_IS_CALL';
export const SET_IAMINCALL = 'SET_IAMINCALL';
export const SET_IS_INCOMMING = 'SET_IS_INCOMMING';
export const SET_IS_OUTGOING = 'SET_IS_OUTGOING'; 
export const SET_TIMERINCALL = 'SET_TIMERINCALL';
export const SET_OUTTEL = 'SET_OUTTEL';
export const SET_MTELSIP = 'SET_MTELSIP';
export const SET_IS_HOLD = 'SET_IS_HOLD';
export const SET_IS_PAUSE = 'SET_IS_PAUSE';
export const SET_IS_PAUSE_TIMER = 'SET_IS_PAUSE_TIMER';
export const SET_IS_PAUSE_PERM = 'SET_IS_PAUSE_PERM';
export const SET_SESSION = 'SET_SESSION';
export const SET_UA = 'SET_UA';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_SCHEME = 'SET_SCHEME';
export const SET_SIP_LOADING = 'SET_SIP_LOADING';