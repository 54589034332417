
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap' 
import classNames from 'classnames'
import { FiPhoneMissed } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setIsHold } from 'store/sip/actions'
import AppContext from 'context/Context'

const MobileHoldBtn = () => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { session, isHold, iamincall } = useSelector(state => state.sip);
    const dispatch = useDispatch()

    const toggleHold = () => {
        if (session) {
            if (session.isOnHold().local) {
                session.unhold();
                dispatch(setIsHold(false))
            } else {
                session.hold();
                dispatch(setIsHold(true))
            }
        }
    }

    return (
        <Button
            variant={isHold ? 'outline-warning' : "outline-primary"}
            className={classNames('flex-grow-1 py-3 text-nowrap shadow-none', { "bg-200": !isDark })}
            size='lg'
            onClick={() => {
                toggleHold()
            }}
            disabled={!iamincall}
        >
            <FiPhoneMissed />
        </Button>
    )
}

export default MobileHoldBtn