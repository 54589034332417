import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import TooltipItem from 'components/common/TooltipItem'
import AppContext from 'context/Context'
import React, { useContext } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { isIterableArray } from '../../../helpers/utils' 
import Flex from '../../common/Flex'
import { borderColors } from './sipInitialBase'

const Keyboard = ({ keyboardArray, handleClose, handleKey, isMobile }) => {

    const {
        config: { isDark }
      } = useContext(AppContext);

    const renderKeyboardArray = () => {
        const arrayRender =  isMobile ? keyboardArray : keyboardArray.slice(0, 9)
        return (
            isIterableArray(keyboardArray) ?
                <TooltipItem
                    tooltipText={keyboardArray.map((item, index) => {
                        if (keyboardArray.length - 1 !== index) return item + ' - '
                        return item
                    })}
                    placement='bottom'
                >
                    <div className={`d-flex overflow-hidden  ${isMobile && 'mb-2'}`}>
                        {
                            arrayRender.map((item, index) =>
                                <>
                                    <h6 key={index} className={`ms-1 mb-0  ${isMobile && 'fs-2'}`}>{item + " "}</h6> 
                                </>
                            )
                        }
                    </div>
                </TooltipItem >
                :
                ''
        )
    }

    const MainContent = () => (
        <div className={isMobile ? 'mobile-keyboard' : ''}>
            <Flex justify={isMobile ? 'stretch' : 'center'} >
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(1)}>1</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(2)}>2</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(3)}>3</Button>
            </Flex>
            <Flex justify={'center'}>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(4)}>4</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(5)}>5</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(6)}>6</Button>
            </Flex>
            <Flex justify={'center'}>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(7)}>7</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(8)}>8</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(9)}>9</Button>
            </Flex>
            <Flex justify={'end'}>
                <Button
                    size={isMobile ? 'lg' : 'md'}
                    className={isMobile && "flex-grow-1"}
                    variant='falcon-default'
                    onClick={() => handleKey('*')}
                    style={{ borderBottomLeftRadius: '0.375rem' }}
                >✱</Button>
                <Button size={isMobile ? 'lg' : 'md'} className={isMobile && "flex-grow-1"} variant='falcon-default' onClick={() => handleKey(0)}>0</Button>
                <Button
                    size={isMobile ? 'lg' : 'md'}
                    className={isMobile && "flex-grow-1"}
                    variant='falcon-default'
                    onClick={() => handleKey('#')}
                    style={{ borderBottomRightRadius: '0.375rem' }}
                >#</Button>
                {/* <FontAwesomeIcon icon={faBackspace} />  */}
            </Flex>
        </div>
    )
    return (
        isMobile ?
            <>
                {renderKeyboardArray()}
                <MainContent />
            </>
            :
            <div className='jssipModal-container mini-keyboard '
                style={{
                    borderColor: isDark ? borderColors.dark : borderColors.light,
                }}
            >
                <Card>
                    <Card.Header className={'bg-light card-footer py-2 px-0'}>
                        <Row className="align-items-center g-0">
                            <Col>
                                {renderKeyboardArray()}
                            </Col>
                            <Col className='text-right'>
                                <div className='me-2 cursor-pointer' onClick={handleClose}>
                                    <FontAwesomeIcon icon={'times'} />
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <MainContent />
                    </Card.Body>
                </Card>
            </div>
    )
}

export default Keyboard