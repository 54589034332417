import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar'; 
import { useSelector } from 'react-redux';
import AppContext from 'context/Context';

const ProfileDropdown = () => {

  const appState = useSelector(state => state.appState)

  const { setConfig } = useContext(AppContext);

  const handleClick = () => {
    setConfig('showSettingPanel', true);
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      > 
          <FontAwesomeIcon className='text-primary' icon='user-circle' size='2x' /> 
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          
          
          <Dropdown.Item as={Link} to={`/user/staff/${appState.staff_id}`}>
            Моя страница
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleClick}  className='d-md-none' >
            Настройки вида
          </Dropdown.Item>
          <Dropdown.Divider  className='d-md-none' />
          <Dropdown.Item as={Link} to="/auth/logout" className='text-danger'>
            Выйти
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
