import axios from 'axios';
import { getItemFromStore, removeItemFromStore, setItemToStore } from '../helpers/utils';
import { hostingApi, redirectStore } from '../config';

/** @type {Array} */
let requestsToRefresh = [];

/** @type {boolean} */

const storageName = getItemFromStore('authStorage', 'local') 
const currentStorage = storageName === 'local' ? localStorage : sessionStorage 

let isRefreshRequesting = false;
let access_token = getItemFromStore('access_token', '', currentStorage);
axios.defaults.headers['Authorization'] = 'Bearer ' + access_token;

const setToken = data => {
  console.log(`Set token: ${data}`);
  setItemToStore('access_token', data.access_token, currentStorage);
  setItemToStore('refresh_token', data.refresh_token, currentStorage);
  axios.defaults.headers['Authorization'] = 'Bearer ' + data.access_token;
};

const clearToken = () => {
  console.log('Clear token');
  removeItemFromStore('refresh_token', currentStorage);
  removeItemFromStore('access_token', currentStorage);
  removeItemFromStore('isAuth', currentStorage);
  axios.defaults.headers['Authorization'] = null;
};


axios.interceptors.response.use(null, err => {
  const { response, config } = err;
  if (!!response && response.status === 404) {
    // document.location.replace("/404"); 
    console.log(response)
  }
  if (!!response && response.status === 401) {
    // If we have not logged in before, it makes no sense
    // to try to get a new token
    access_token = getItemFromStore('access_token', '', currentStorage);

    if (!access_token) {
      clearToken()
      document.location.replace("/auth/login");
      setItemToStore(redirectStore, window.location.pathname, sessionStorage)
      return Promise.reject(err);
    }

    // User is auth, probably token is expired, try renew
    // And send all failed requests again
    if (!isRefreshRequesting) {
      isRefreshRequesting = true;
      console.log('Request for a new token');

      // Send request to refresh token
      let refresh_token = getItemFromStore('refresh_token', '', currentStorage);
      axios
        .put(`${hostingApi}/auth/update`, { refresh_token: refresh_token })
        .then(({ data }) => {
          // вот это не срабатывает
          console.log('New token received');
          setToken(data);
          requestsToRefresh.forEach(cb => cb(data.access_token));

        })
        .catch(() => {
          // If you have a closed system, you can also
          // redirect/router to the login page 
          console.log('An error occurred while getting a new token');
          clearToken();
          requestsToRefresh.forEach(cb => cb(null));

          document.location.replace("/auth/login");
          setItemToStore(redirectStore, window.location.pathname, sessionStorage)
        })
        .finally(() => {
          console.log('Clear queue of failed requests');
          requestsToRefresh = [];
          isRefreshRequesting = false;
        });
    }

    if (response.config.url === `${hostingApi}/auth/update`) {
      console.log("Redirect by token update error")
      clearToken();
      setItemToStore(redirectStore, window.location.pathname, sessionStorage)
      document.location.replace("/auth/login");
    }

    console.log(`The request is waiting for a new token.. [${response.config.url}]`);
    return new Promise((resolve, reject) => {
      // In our variable (requests that expect a new token
      // from the first request), we add a callback,
      // which the first request to execute
      requestsToRefresh.push(token => {
        if (token) {
          config.headers.Authorization = 'Bearer ' + token;
          resolve(axios(config));
        }

        // If the first request could not update the token, we
        // must return the basic request processing logic
        reject(err);
      });
    });
  }

  return Promise.reject(err);
});


axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const access_token = getItemFromStore('access_token', '', currentStorage);
  config.headers.Authorization = 'Bearer ' + access_token;

  return config;
}, function (error) {
  // Do something with request error 

  setItemToStore(redirectStore, window.location.pathname, sessionStorage)
  clearToken()
  document.location.replace("/auth/login");

  console.log(error);
  // return Promise.reject(error);
});

//functions to make api calls
const api = {
  signup: body => {
    return axios.post(`${hostingApi}/auth/sign-up`, body);
  },
  login: body => {
    return axios.post(`${hostingApi}/auth/sign-in`, body);
  },
  refreshToken: body => {
    return axios.post(`${hostingApi}/auth/refresh_token`, body);
  },
  logout: body => {
    return axios.delete(`${hostingApi}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${hostingApi}/orders/add`);
  },
  post: (uri, body, config) => {
    return axios.post(`${hostingApi}/${uri}`, body, config);
  },
  put: (uri, body) => {
    return axios.put(`${hostingApi}/${uri}`, body);
  },
  patch: (uri, body) => {
    return axios.patch(`${hostingApi}/${uri}`, body);
  },
  get: (uri, body) => {
    return axios.get(`${hostingApi}/${uri}`, body);
  },
  delete: (uri, body) => {
    return axios.delete(`${hostingApi}/${uri}`, body);
  },
  sendfile: (body) => {
    return axios.post(`${hostingApi}/file/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
};
export default api;
