import * as actions from './actionTypes';

const initialState = {
    isHold: false,
    isMute: false,
    isReg: false,
    isSip: false,
    iamincall: false,
    isIncoming: false,
    isOutgoing: false,
    timerincall: false,
    outtel: 0,
    mtelsip: 0,
    isPause: false, // чтобы рисовать нужные кнопки
    isPauseTimer: false, // временная пауза
    isPausePerm: false, // постоянная пауза
    session: null,
    ua: null,
    localStream: null,
    scheme: 0, // 0 - по умолчанию. если 0 то первая схема, если 2 - вторая
    sipLoading: false
}

export default function sipReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_IS_MUTE:
            return {
                ...state, ...{
                    isMute: action.payload,
                }
            };
        case actions.SET_IS_REG:
            return {
                ...state, ...{
                    isReg: action.payload,
                }
            };
        case actions.SET_IS_SIP:
            return {
                ...state, ...{
                    isSip: action.payload,
                }
            };
        case actions.SET_IS_INCOMMING:
            return {
                ...state, ...{
                    isIncoming: action.payload,
                }
            };
        case actions.SET_IS_OUTGOING:
            return {
                ...state, ...{
                    isOutgoing: action.payload,
                }
            };
        case actions.SET_IAMINCALL:
            return {
                ...state, ...{
                    iamincall: action.payload,
                }
            };
        case actions.SET_TIMERINCALL:
            return {
                ...state, ...{
                    timerincall: action.payload,
                }
            };
        case actions.SET_OUTTEL:
            return {
                ...state, ...{
                    outtel: action.payload,
                }
            };
        case actions.SET_MTELSIP:
            return {
                ...state, ...{
                    mtelsip: action.payload,
                }
            };
        case actions.SET_IS_HOLD:
            return {
                ...state, ...{
                    isHold: action.payload,
                }
            };
        case actions.SET_IS_PAUSE:
            return {
                ...state, ...{
                    isPause: action.payload,
                }
            };
        case actions.SET_IS_PAUSE_TIMER:
            return {
                ...state, ...{
                    isPauseTimer: action.payload,
                }
            };
        case actions.SET_IS_PAUSE_PERM:
            return {
                ...state, ...{
                    isPausePerm: action.payload,
                }
            };
        case actions.SET_SESSION:
            return {
                ...state, ...{
                    session: action.payload,
                }
            };
        case actions.SET_UA:
            return {
                ...state, ...{
                    ua: action.payload,
                }
            };
        case actions.SET_LOCAL_STREAM:
            return {
                ...state, ...{
                    localStream: action.payload,
                }
            };
        case actions.SET_SCHEME:
            return {
                ...state, ...{
                    scheme: action.payload,
                }
            };
        case actions.SET_SIP_LOADING:
            return {
                ...state, ...{
                    sipLoading: action.payload,
                }
            };

        default:
            return state;
    }
}  