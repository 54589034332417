import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import InputMask from 'react-input-mask';

import FalconCardHeader from 'components/common/FalconCardHeader'
import { MdOutlinePhonelinkSetup } from 'react-icons/md'
import { borderColors, phoneInputTypes } from './sipInitialBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useSelector } from 'react-redux';
import { FaUsers } from 'react-icons/fa';
import { FiUserX } from 'react-icons/fi';
import { TiCancel } from 'react-icons/ti';
import TooltipItem from 'components/common/TooltipItem';
import AppContext from 'context/Context';

const TransferBlock = ({ isMobile, handleClose }) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const transferTypesData = [
        {
            type: 'consultation',
            value: '*2',
            label: 'С консультацией'
        },
        {
            type: 'no-consultation',
            value: '##',
            label: 'Без консультации'
        }
    ]

    const { session, iamincall } = useSelector(state => state.sip);

    const [isConsTransfer, setIsConsTransfer] = useState(false);
    const [transferType, setTransferType] = useState(transferTypesData[0]);
    const [phoneInput, setPhoneInput] = useState('');
    const [inputType, setInputType] = useState(phoneInputTypes.inner);

    const [isDisableBtn, setIsDisableBtn] = useState(true);

    const handleTransfer = () => {
        if (session) {
            if (transferType.type === 'consultation') setIsConsTransfer(true)
            setIsDisableBtn(true)
            session.sendDTMF(transferType.value);
            session.sendDTMF(phoneInput.replaceAll(/[^\d]/g, ''));
        }
    }

    const handleCons = (value) => {
        if (session) {
            console.log(value)
            session.sendDTMF(value);
            if (value === '*4') {
                setIsConsTransfer(false)
                setIsDisableBtn(false)
            }
        }
    }

    useEffect(() => {
        if (phoneInput.replaceAll(/[^\d]/g, '').length !== inputType.length) setIsDisableBtn(true)
        else setIsDisableBtn(false)
    }, [phoneInput]);

    useEffect(() => {
        if (!iamincall) {
            console.log(iamincall)
            setIsConsTransfer(false)
            setIsDisableBtn(false)
        }
    }, [iamincall]);


    return (
        !isMobile ?
            <div className='jssipModal-container mt-2'
                style={{
                    borderColor: isDark ? borderColors.dark : borderColors.light,
                }}
            >
                <Card>
                    <FalconCardHeader
                        title={'Перевод звонка'}
                        titleClass={'fs--1'}
                        className="card-footer py-2 px-3"
                        light={true}
                        endEl={
                            <div className='cursor-pointer' onClick={handleClose}>
                                <FontAwesomeIcon icon={'times'} />
                            </div>
                        }
                    />
                    <Card.Body className='px-2 pb-2' >
                        <div>
                            {/* mainContent */}
                            <Dropdown className='me-2'>
                                <Dropdown.Toggle
                                    bsPrefix='toggle'
                                    variant='falcon-default'
                                    size='sm'
                                    className=' text-900 unfocus'
                                >
                                    {transferType.label} <FontAwesomeIcon icon={'chevron-down'} className='ms-1 mt-1' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='py-0'>
                                    {
                                        transferTypesData.map((item, index) => {
                                            return (
                                                <Dropdown.Item
                                                    className='py-2'
                                                    key={index}
                                                    disabled={transferTypesData[index].value === transferType.value}
                                                    onClick={() => {
                                                        setTransferType(item)
                                                    }}
                                                >
                                                    {item.label}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <Flex className={'my-3'} alignItems='center'>
                                <Dropdown className='me-2'>
                                    <Dropdown.Toggle
                                        bsPrefix='toggle'
                                        variant='link'
                                        size={isMobile ? 'lg' : 'sm'}
                                        className='px-0 py-0 text-900 unfocus'
                                    >
                                        <MdOutlinePhonelinkSetup size={29} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-0'>
                                        {
                                            Object.keys(phoneInputTypes).map((key, index) => {
                                                const item = phoneInputTypes[key]
                                                return (
                                                    <Dropdown.Item
                                                        className='py-2'
                                                        key={index}
                                                        disabled={phoneInputTypes[key].value === inputType.value}
                                                        onClick={() => {
                                                            if (phoneInputTypes[key].value !== inputType.value) {
                                                                setInputType(phoneInputTypes[key])
                                                                setPhoneInput('')
                                                            }
                                                        }}
                                                    >
                                                        {item.label}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <InputMask
                                    type='tel'
                                    className={`form-control me-2 `}
                                    mask={inputType.mask}
                                    autoComplete="off"
                                    placeholder={inputType.placeholder}
                                    value={phoneInput}
                                    onChange={
                                        (e) => setPhoneInput(e.target.value)
                                    }
                                />
                                <Button
                                    variant="falcon-success"
                                    disabled={isDisableBtn}
                                    onClick={handleTransfer}
                                    size={isMobile ? 'lg' : 'md'}
                                >
                                    Перевод
                                </Button>
                            </Flex>
                            {
                                isConsTransfer &&
                                <Flex justifyContent={'end'} alignItems='center'>
                                    <TooltipItem
                                        tooltipText={'Трёхсторонняя конференция'}
                                    >
                                        <Button
                                            variant="falcon-success"
                                            className='me-2 py-1 px-2'
                                            onClick={() => handleCons('*3')}
                                            size={isMobile ? 'lg' : 'md'}
                                        >
                                            <FaUsers size={23} />
                                        </Button>
                                    </TooltipItem>
                                    <TooltipItem
                                        tooltipText={'Отменить перевод'}
                                    >
                                        <Button
                                            variant="falcon-danger"
                                            className='me-2 py-1 px-2'
                                            onClick={() => handleCons('*5')}
                                            size={isMobile ? 'lg' : 'md'}
                                        >
                                            <FiUserX size={23} />
                                        </Button>
                                    </TooltipItem>
                                    <TooltipItem
                                        tooltipText={'Завершить перевод'}
                                    >
                                        <Button
                                            variant="danger"
                                            className='py-1 px-2'
                                            onClick={() => handleCons('*4')}
                                            size={isMobile ? 'lg' : 'md'}
                                        >
                                            <TiCancel size={23} />
                                        </Button>
                                    </TooltipItem>
                                </Flex>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </div>
            :
            <div>
            // mainContent
                <Dropdown className='me-2'>
                    <Dropdown.Toggle
                        bsPrefix='toggle'
                        variant='falcon-default'
                        size='sm'
                        className=' text-900 unfocus'
                    >
                        {transferType.label} <FontAwesomeIcon icon={'chevron-down'} className='ms-1 mt-1' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='py-0'>
                        {
                            transferTypesData.map((item, index) => {
                                return (
                                    <Dropdown.Item
                                        className='py-2'
                                        key={index}
                                        disabled={transferTypesData[index].value === transferType.value}
                                        onClick={() => {
                                            setTransferType(item)
                                        }}
                                    >
                                        {item.label}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Flex className={'my-3'} alignItems='center'>
                    <Dropdown className='me-2'>
                        <Dropdown.Toggle
                            bsPrefix='toggle'
                            variant='link'
                            size={isMobile ? 'lg' : 'sm'}
                            className='px-0 py-0 text-900 unfocus'
                        >
                            <MdOutlinePhonelinkSetup size={29} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='py-0'>
                            {
                                Object.keys(phoneInputTypes).map((key, index) => {
                                    const item = phoneInputTypes[key]
                                    return (
                                        <Dropdown.Item
                                            className='py-2'
                                            key={index}
                                            disabled={phoneInputTypes[key].value === inputType.value}
                                            onClick={() => {
                                                if (phoneInputTypes[key].value !== inputType.value) {
                                                    setInputType(phoneInputTypes[key])
                                                    setPhoneInput('')
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <InputMask
                        type='tel'
                        className={`form-control me-2 `}
                        mask={inputType.mask}
                        autoComplete="off"
                        placeholder={inputType.placeholder}
                        value={phoneInput}
                        onChange={
                            (e) => setPhoneInput(e.target.value)
                        }
                    />
                    <Button
                        variant="falcon-success"
                        disabled={isDisableBtn}
                        onClick={handleTransfer}
                        size={isMobile ? 'lg' : 'md'}
                    >
                        Перевод
                    </Button>
                </Flex>
                {
                    isConsTransfer &&
                    <Flex justifyContent={'end'} alignItems='center'>
                        <TooltipItem
                            tooltipText={'Трёхсторонняя конференция'}
                        >
                            <Button
                                variant="falcon-success"
                                className='me-2 py-1 px-2'
                                onClick={() => handleCons('*3')}
                                size={isMobile ? 'lg' : 'md'}
                            >
                                <FaUsers size={28} />
                            </Button>
                        </TooltipItem>
                        <TooltipItem
                            tooltipText={'Отменить перевод'}
                        >
                            <Button
                                variant="falcon-danger"
                                className='me-2 py-1 px-2'
                                onClick={() => handleCons('*5')}
                                size={isMobile ? 'lg' : 'md'}
                            >
                                <FiUserX size={28} />
                            </Button>
                        </TooltipItem>
                        <TooltipItem
                            tooltipText={'Завершить перевод'}
                        >
                            <Button
                                variant="danger"
                                className='py-1 px-2'
                                onClick={() => handleCons('*4')}
                                size={isMobile ? 'lg' : 'md'}
                            >
                                <TiCancel size={28} />
                            </Button>
                        </TooltipItem>
                    </Flex>
                }
            </div>
    )
}

export default TransferBlock