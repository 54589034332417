import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import { FormatPhone, isIterableArray } from 'helpers/utils'
import { Card } from 'react-bootstrap'
import { borderColors } from './sipInitialBase'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import api from 'api/api'
import Loader from 'components/common/Loader'
import SoftBadge from 'components/common/SoftBadge'
import DividerLeft from 'components/common/DividerLeft'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context'

const PhoneInfoList = ({ isMobile, handleClose }) => {

    const {
        config: { isDark }
    } = useContext(AppContext);

    const { mtelsip, outtel, isIncoming, isOutgoing, scheme } = useSelector(state => state.sip)

    const [loadingUsers, setLoadingUsers] = useState(true);
    const [userList, setUserList] = useState([]);

    const [siteList, setSiteList] = useState([]);
    const [loadingSites, setLoadingSites] = useState(true);

    const [selectedPhoneUsers, setSelectedPhoneUsers] = useState(''); // выбранный телефон про который показывается инфа
    const [selectedPhoneSites, setSelectedPhoneSites] = useState(''); // выбранный телефон про который показывается инфа

    useEffect(() => {
        // console.log('outtel - входящий;  mtel - исходящий')
        // console.log('outtel', isIncoming, outtel)
        // console.log('mtel', isOutgoing, outtel)

        // setLoadingUsers(false)
        // setLoadingSites(false)



        if (isIncoming && mtelsip && outtel && !scheme) {
            console.log('this 1')
            fetchPhone(mtelsip)
            fetchSiteList(outtel)
            setSelectedPhoneUsers(FormatPhone(mtelsip))
            setSelectedPhoneSites(FormatPhone(outtel))
            return
        }
        if (isIncoming && scheme) {
            console.log('this 2')
            fetchPhone(mtelsip)
            fetchSiteList(outtel)
            setSelectedPhoneUsers(FormatPhone(mtelsip))
            setSelectedPhoneSites(FormatPhone(outtel))
            return
        }
        if (isIncoming && mtelsip && !scheme) {
            console.log('this 3')
            fetchPhone(mtelsip)
            // fetchSiteList(outtel)
            setLoadingSites(false)
            setSelectedPhoneUsers(FormatPhone(mtelsip))
            // setSelectedPhoneSites(FormatPhone(outtel))
            return
        }
        if (isOutgoing && mtelsip) {
            console.log('this 4')
            fetchPhone(mtelsip)
            // fetchSiteList(mtelsip)
            setLoadingSites(false)
            setSiteList([])
            setSelectedPhoneUsers(FormatPhone(mtelsip))
            setSelectedPhoneSites(0)
            return
        }
    }, [mtelsip, outtel]);

    const fetchPhone = (phone) => {
        api.post(`integration/nums/aspirants`, {
            phone: Number(phone)
        })
            .then(res => {
                if (res.data) setUserList(res.data)
                else setUserList([])
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoadingUsers(false)
            })
    }
    const fetchSiteList = (phone) => {
        // setLoadingSites(true)
        const replaced = String(phone).replaceAll('+', '')
        console.log(replaced)
        if (replaced) api.get(`integration/site/list/1?rows=10&q=${replaced}`)
            .then(res => {
                if (res.data.list) {
                    setSiteList(res.data.list)
                }
                else setSiteList([])
            })
            .catch(error => {
                if (error.message) {
                    toast.error(error.message)
                }
                console.error(error);
            })
            .finally(() => {
                setLoadingSites(false)
            })
    }


    const MainContent = () => (
        <>
            {
                loadingUsers ?
                    <Loader size='sm' />
                    :
                    isIterableArray(userList) && userList.length > 0 ?
                        userList.map((item, index) => (
                            <Flex key={index} alignItems='center' justifyContent='between' className={`px-3 py-2`} >
                                <NavLink
                                    className={isMobile && 'fs-2'}
                                    to={`/user/aspirant/${item.sub_type}/${item.id}`}
                                >
                                    {item.fio} (id: {item.id})
                                </NavLink>
                                <h6 className={`mb-0 text-center text-uppercase text-600 text-nowrap ${isMobile && 'fs-1'}`}>
                                    {item.tip_name}
                                    <FontAwesomeIcon icon="user" className="ms-1" />
                                </h6>
                            </Flex>
                        )) :
                        <Flex alignItems={'start'} className={`px-3 py-2`}>
                            <p className='mb-0 fs--1 text-500'>
                                Создать {` `}
                                <Link to={`/aspirant-create/founder`}>соискателя</Link>{`, `}
                                <Link to={`/aspirant-create/pupils`}>ученика</Link>
                            </p>
                        </Flex>
            }
            {
                loadingSites ?
                    <Loader size='sm' />
                    :
                    isIterableArray(siteList) ?
                        <>
                            <DividerLeft className={isMobile && 'fs-2'}>Сайты ( по номеру: {selectedPhoneSites})</DividerLeft>
                            {
                                siteList.map((item, index) => (
                                    <Flex key={index} alignItems='center' className={'px-3 py-2'} >
                                        <h5 className='text-500 fs--1 mb-0 me-2'>{item.domain_value}</h5>

                                        <SoftBadge pill bg='primary' >
                                            <a
                                                className="d-block"
                                                href={item.domain_value}
                                                target='_blank'
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} />

                                            </a>
                                        </SoftBadge>

                                    </Flex>
                                ))
                            }
                        </>
                        :
                        <DividerLeft textClassName={isMobile && 'fs-1'} >Сайтов нет  ( по номеру: {selectedPhoneSites})</DividerLeft>
            }
        </>
    )
    return (
        !isMobile ?
            <div className='jssipModal-container mt-2'
                style={{
                    borderColor: isDark ? borderColors.dark : borderColors.light,
                }}
            >
                <Card >
                    <FalconCardHeader
                        title={
                            userList?.length > 0 ?
                                `Инфо ( по номеру: ${selectedPhoneUsers})`
                                :
                                `Информации о номере нет`
                        }
                        titleClass={'fs--1'}
                        className="card-footer py-2 px-3"
                        light={true}
                    />
                    <Card.Body className='p-0 scrollbar' style={{ maxHeight: 250 }}>
                        <MainContent />
                    </Card.Body>
                </Card>
            </div>
            :
            <div className='py-3 bg-light' >
                <DividerLeft textClassName={'fs-1'} >
                    {
                        userList?.length > 0 ?
                            `Информация о номере (${selectedPhoneUsers})`
                            :
                            `Информация о номере нет (${selectedPhoneUsers})`
                    }
                </DividerLeft>
                <MainContent />
            </div>
    )
}

export default PhoneInfoList