
import ringtoneDefault from '../../../assets/sound/ringtone-default.mp3'
import ringtoneBelieve from '../../../assets/sound/ringtone-believe.mp3'
import { phoneMask } from 'config'

export const ringtoneBase = [
    {
        id: 1, // id 1 - будет по умолчанию при первом запуске
        title: 'Default',
        src: ringtoneDefault
    },
    {
        id: 2,
        title: 'Believe',
        src: ringtoneBelieve,
    }
]

export const storageRingtoneName = "falcon-sip-ringtone"
export const storageCallHistoryName = "falcon-sip-call-history"

export const textSipOffline = 'Сначала подключитесь к телефонии'

export const pauseDuration = 10

export const borderColors = { // цвета границ для плавающих окошек
    dark: '#01070f',
    light: '#b5becb'
}

export const sipoptions = {
    'mediaConstraints': { 'audio': true, 'video': false },
    'rtcOfferConstraints': { offerToReceiveAudio: 1, offerToReceiveVideo: 0 },
    'pcConfig':
    {
        hackStripTcp: true,
        rtcpMuxPolicy: 'negotiate',
        iceServers: []
    }
};

export const phoneInputTypes = {
    phone: {
        label: 'Мобильный',
        value: 'phone',
        mask: phoneMask,
        placeholder: phoneMask,
        length: 11
    },
    inner: {
        label: 'Внутренний (800000-899999)',
        value: 'inner',
        mask: '899999',
        placeholder: '800000',
        length: 6
    },
    four: {
        label: '4-х',
        value: 'four',
        mask: '9999',
        placeholder: '9999',
        length: 4
    },
}