
import React, { useContext, useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { faGlobeEurope, faPause, faPauseCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SipContext } from '../../../context/Context'

import Flex from '../../common/Flex'
import { renderTime } from '../../../helpers/utils'
import { pauseDuration } from './sipInitialBase'

const SipToggle = () => {

    const timerRef = useRef(null)
    const pauseTimerRef = useRef(null)

    const { headerOpen, setHeaderOpen } = useContext(SipContext)
    const appState = useSelector(state => state.appState)

    const visibleName = appState?.softphone.num

    const { isSip, isIncoming, isOutgoing, timerincall, isPause, isPauseTimer, isPausePerm } = useSelector(state => state.sip);

    const isCall = isIncoming || isOutgoing

    const [timercall, setTimercall] = useState(0);
    const [timerpause, setTimerpause] = useState(pauseDuration);

    const handleToggle = () => {
        setHeaderOpen(prev => !prev)
    }

    useEffect(() => {
        clearInterval(timerRef.current)
        if (timerincall) {
            timerRef.current = setInterval(() => {
                setTimercall(prev => prev + 1)
            }, [1000]);
        }
        else {
            setTimercall(0)
        }
    }, [timerincall])


    useEffect(() => {
        clearInterval(pauseTimerRef.current)
        if (isPauseTimer) {
            pauseTimerRef.current = setInterval(() => {
                setTimerpause(prev => prev - 1)
            }, [1000]);
        }
        else {
            setTimerpause(pauseDuration)
        }
    }, [isPauseTimer])

    const colorFormatter = () => {
        if (isPause) return 'text-warning'
        if (isSip) return 'text-success'
        else return 'text-danger'
    }

    const iconFormatter = () => {
        if (isPause) return faPauseCircle
        if (isCall) return 'phone'
        else return faGlobeEurope
    }

    const SipCurrentIcon = () => {
        return (
            <>
                <FontAwesomeIcon
                    icon={iconFormatter()}
                    size='lg'
                    className={`me-1 ${colorFormatter()} ${isPause && 'blick'}`}
                />
                {
                    isPauseTimer && <span className='text-warning me-2'>{timerpause}</span>
                }
            </>

        )
    }

    if (!visibleName) {
        return ''
    }


    return (
        <Flex
            alignItems={'center'}
            className='py-0 cursor-pointer'
            onClick={handleToggle}
        >
            <SipCurrentIcon />
            {
                isCall ?
                    <h5 className=' fs--1 mb-0 me-2'>
                        {isIncoming && 'Входящий '}
                        {isOutgoing && 'Исходящий '}
                        вызов
                        {
                            timerincall && <span className='fs--1 ms-1'>{renderTime(timercall)}</span>
                        }
                    </h5>
                    :
                    <>
                        <span className='me-2'>{visibleName}</span>
                    </>
            }
            <FontAwesomeIcon icon={headerOpen ? 'times' : 'chevron-down'} size='sm' />
        </Flex>
    )
}

export default SipToggle