import {createStore, combineReducers} from 'redux'
import sipReducer from './sip/reducer';
import appStateReducer from './appState/reducer'
 
const rootReducer = combineReducers({
    sip: sipReducer,
    appState: appStateReducer
})

const store = createStore(rootReducer);

export default store;