import React, { useState, useContext, useEffect, memo } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setAppState } from 'store/appState/actions';
import api from '../api/api';
import Loader from '../components/common/Loader';

const AppStateLayout = memo(function AppStateLayout({ children }) {

    const dispatch = useDispatch()

    const [isFetched, setIsFetched] = useState(false); 

    useEffect(() => {
        if (!isFetched) {
            fetchAppState()
        }
    }, []);

    const fetchAppState = async () => {
        await api.get('app/state').then((res) => {
            if (res.status === 200) {
                dispatch(setAppState(res.data))
                setIsFetched(true)
            }
            else {
                toast.error('Произошла ошибка при загрузке')
            }
        })
            .catch(error => {
                console.error(error)
                toast.error('Произошла ошибка при загрузке app/state')
            })
    }

    return (
        isFetched ?
            children
            : 
            <Loader className='mx-0 my-5' />
    )
})

export default AppStateLayout