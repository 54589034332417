
import React, { useContext, useEffect, useState, useRef } from 'react'

import { v4 as uuid } from 'uuid'

import '../../../assets/scss/chunks/sip.scss'

import { SipContext } from '../../../context/Context';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPause, setIsPausePerm, setSipLoading, setOuttel, setSession, setUa } from 'store/sip/actions';

import clickSound from '../../../assets/sound/clickSound.mp3'
import { sipoptions, storageCallHistoryName } from './sipInitialBase';

import { useBeforeunload } from 'react-beforeunload';
import api from 'api/api';
import { CALL_FROM_MODAL, REG_OFF, REG_ON, SIP_OFF, SIP_ON } from '../in-stat/types';
import { setInStat } from '../in-stat/SetInStat';
import SipBoxMobile from './SipBoxMobile';
import SipBoxDesktop from './SipBoxDesktop';
import { getItemFromStore, setItemToStore } from 'helpers/utils';


const SipBox = ({ ua }) => {

    const btnSound = useRef(null)

    const dispatch = useDispatch()
    const { session, iamincall, isReg, isSip, isIncoming, isOutgoing, outtel, mtelsip } = useSelector(state => state.sip);

    const { headerOpen } = useContext(SipContext);

    // let isFirstStartSip = true
    const [isFirstStartSip, setIsFirstStartSip] = useState(true);

    const [keyboardArray, setKeyboardArray] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    const updateType = () => {
        if (window.innerWidth < 992) {
            setIsMobile(true)
        }
        else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        updateType()
        window.addEventListener('resize', updateType);
        return () => window.removeEventListener('resize', updateType);
    }, []);


    useBeforeunload((event) => {
        if (isSip || isReg) {
            event.preventDefault();
            // return 'При перезагрузке сайта вы потеряете соединение'
        }
    });


    useEffect(() => {
        dispatch(setUa(ua))
        return () => {
            if (isSip || isReg) {
                ua.stop()
            }
        };
    }, [ua]);

    useEffect(() => { // sip-lazy
        if (isIncoming && mtelsip) {
            createCall({
                type: 'incoming',
                tel: mtelsip,
                date: new Date()
            })
        }
    }, [isIncoming, mtelsip]);

    useEffect(() => { // sip-lazy
        if (isOutgoing && mtelsip) {
            createCall({
                type: 'outgoing',
                tel: mtelsip,
                date: new Date()
            })
        }
    }, [isOutgoing, mtelsip]);

    useEffect(() => { // sip-lazy
        if (!iamincall) setKeyboardArray([])
    }, [iamincall]);

    const createCall = ({ type, tel, date }) => { // для записи в локальную историю звонков 
        const callHistory = getItemFromStore(storageCallHistoryName, [])
        callHistory.unshift({
            id: uuid(), type, tel, date
        })
        const items30 = callHistory.slice(0, 30)
        setItemToStore(storageCallHistoryName, JSON.stringify(items30))
    }




    const firstStart = () => {
        if (ua) {
            console.log('firststart')
            // setIsFirstStartSip(false)

            // получение доступа к микрофону на старте
            navigator.getUserMedia = (
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia
            );
            navigator.getUserMedia(
                {
                    audio: true, video: false
                },
                function (stream) {
                    stream.getTracks().forEach(function (track) {
                        if (track.readyState == 'live' && track.kind === 'audio') {
                            track.stop();
                        }
                    });
                },
                function (error) {
                    console.log(error)
                }
            )

            ua.start();

            setInStat({
                out_tel: 0,
                phone: 0,
                tip: SIP_ON
            })
        } else {
            toast.warn("Не удалось выполнить подключение")
        }
    }

    const handleConnectSip = () => {
        console.log('go connect')
        dispatch(setSipLoading(true))

        if (isFirstStartSip) {
            ua.register();
            firstStart()
        }
        if (ua && !ua.isRegistered()) {
            ua.register();
        }
    }

    const unRegister = () => {
        if (ua && ua.isRegistered()) {
            const options = {
                all: true
            };
            ua.unregister(options);
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: REG_OFF
            })
        }
    }

    const handleToggleRegisterSip = () => {
        // setDropdownControl(false)
        if (!ua) return

        if (!ua.isRegistered()) {
            ua.register();
            setInStat({
                out_tel: 0,
                phone: 0,
                tip: REG_ON
            })
        }
        else {
            const options = {
                all: true
            };
            ua.unregister(options);

            setInStat({
                out_tel: 0,
                phone: 0,
                tip: REG_OFF
            })
        }
    }

    const handleDisconnectSip = () => {
        console.log('handleDisconnectSip')
        ua.stop()
        unRegister()
        dispatch(setSipLoading(true))

        setInStat({
            out_tel: 0,
            phone: 0,
            tip: SIP_OFF
        })

        dispatch(setIsPause(false))
        dispatch(setIsPausePerm(false))

        api.post(`integration/ami/pause`, {
            in_pause: false
        })
    }

    const handleCall = (phoneProp) => {
        const phone = phoneProp.replaceAll(' ', '').replace('+', '')
        if (ua && phone) {
            const callsession = ua.call(phone, sipoptions)
            dispatch(setSession(callsession))
            dispatch(setOuttel(0))
            dispatch(setSipLoading(true))

            setInStat({
                out_tel: 0,
                phone: Number(phone),
                tip: CALL_FROM_MODAL
            })
        }
    }

    const handleKeyboardClick = (value) => {
        if (session) {
            session.sendDTMF(value);
            const sliced = keyboardArray.slice()
            sliced.unshift(value)
            setKeyboardArray(sliced)
            btnSound.current.currentTime = 0
            btnSound.current.play()
        }
    }





    return (
        <div className={`sipBox-container ${headerOpen ? 'open' : ''}`}>
            <audio ref={btnSound} src={clickSound} />
            {
                isMobile ?
                    <SipBoxMobile
                        handleToggleRegisterSip={handleToggleRegisterSip}
                        handleDisconnectSip={handleDisconnectSip}
                        handleConnectSip={handleConnectSip}
                        handleCall={handleCall}
                        handleKeyboardClick={handleKeyboardClick}
                        keyboardArray={keyboardArray}
                    />
                    :
                    <SipBoxDesktop
                        handleToggleRegisterSip={handleToggleRegisterSip}
                        handleDisconnectSip={handleDisconnectSip}
                        handleConnectSip={handleConnectSip}
                        handleCall={handleCall}
                        handleKeyboardClick={handleKeyboardClick}
                        keyboardArray={keyboardArray}
                    />
            }
        </div >
    )
}

export default SipBox