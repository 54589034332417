export const MAIN_TITLE = 'SaiCRM - Главная'
export const CREATE_TITLE = 'Создание'
export const ERROR_TITLE = 'Страница не найдена'

export const CREATE_INTERVIEW = 'Интервью - Создание'
export const EDIT_INTERVIEW = 'Интервью - Редактирование'
export const LIST_INTERVIEW = 'Записи на собеседование'
export const ONCE_INTERVIEW = 'Интервью'

export const CREATE_FOUNDER = 'Соискатель - Создание'
export const EDIT_FOUNDER = 'Соискатель - Редактирование'
export const LIST_FOUNDER = 'Список соискателей'
export const ONCE_FOUNDER = 'Соискатель'

export const CREATE_PUPILS = 'Ученик - Создание'
export const EDIT_PUPILS = 'Ученик - Редактирование'
export const LIST_PUPILS = 'Список учеников'
export const ONCE_PUPILS = 'Ученик'

export const CREATE_STAFF = 'Сотрудник'
export const EDIT_STAFF = 'Сотрудник - Редактирование'
export const LIST_STAFF = 'Список сотрудников'
export const ONCE_STAFF = 'Сотрудник'

export const CREATE_SITE = 'Создание сайта'
export const EDIT_SITE = 'Редактирование сайта'
export const LIST_SITE = 'Список сайтов'
export const MAP_SITE = 'Карта сайтов'

export const SETTINGS_NUMBERS = 'Список номеров'
export const SETTINGS_SELECTS = 'Список селектов'

export const REPORTS_HRT = 'Отчеты - HRT'
export const REPORTS_HRS = 'Отчеты - HRS'
export const REPORTS_HRP = 'Отчеты - HRP' 

export const EDIT_CONTACTS = 'Редактирование контактов' 